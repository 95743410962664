import { customerConstants, loginStep } from '../_constants/';

// 
const infos = JSON.parse(sessionStorage.getItem("infos"));

const initialState = infos
    ? {
        hasStep: loginStep.STEP_EMAIL,
        loginRequested: false,
        emailRequested: false,
        emailChecked: false,
        onProgress: false,
        isLoggedIn: true,
        resetToken: null,
        infos: infos
        }
    : {
        hasStep: loginStep.STEP_EMAIL,
        loginRequested: false,
        emailRequested: false,
        emailChecked: false,
        onProgress: false,
        isLoggedIn: false,
        resetToken: null,
        infos: null
    };

export const authReducer = ( state = initialState, action ) => {

    switch (action.type) {
        case customerConstants.AUTHENTICATION:
            return {
                ...state,
                isLoggedIn: true
            }
        case customerConstants.SET_STEP:
            return {
                ...state,
                hasStep: action.step
            }
        case customerConstants.CHECK_EMAIL_REQUEST:
            return {
                ...state,
                hasStep: loginStep.STEP_EMAIL,
                onProgress: true,
                emailRequested: false,
                loginRequested: false,
                emailChecked: false,
                isLoggedIn: false,
                resetToken: null,
                infos: null
            }
        case customerConstants.CHECK_EMAIL_SUCCESS:
            return {
                ...state,
                onProgress: false,
                emailRequested: true,
                emailChecked: action.email,
                resetToken: action.token,
                hasStep: loginStep.STEP_PASSWORD
            }
        case customerConstants.CHECK_EMAIL_FAILURE:
            return {
                ...state,
                onProgress: false,
                emailRequested: true
            }
        case customerConstants.LOGIN_REQUEST:
            return {
                ...state,
                onProgress: true,
                loginRequested:false,
                infos: null
            };
        case customerConstants.LOGIN_SUCCESS:
            return {
                ...state,
                onProgress: false,
                infos: action.infos,
                loginRequested:true,
                isLoggedIn: true
            };
        case customerConstants.LOGIN_FAILURE:
            return {
                ...state,
                onProgress: false,
                loginRequested:true
            };
        case customerConstants.LOGOUT:
            return {
                ...state,
                hasStep: loginStep.STEP_EMAIL,
                loginRequested: false,
                emailRequested: false,
                emailChecked: false,
                onProgress: false,
                isLoggedIn: false,
                resetToken: null,
                infos: null
            };
        default:
            return state
    }
}


