export const LOGOUT = "LOGOUT";

export const customerConstants = {
    SET_STEP: 'SET_STEP',
    AUTHENTICATION: 'AUTHENTICATION',

    LEGAL_CHECK_UPDATE_REQUEST: 'LEGAL_CHECK_UPDATE_REQUEST',
    LEGAL_CHECK_UPDATE_SUCCESS: 'LEGAL_CHECK_UPDATE_SUCCESS',
    LEGAL_CHECK_UPDATE_FAILURE: 'LEGAL_CHECK_UPDATE_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    CHECK_EMAIL_REQUEST: 'CHECK_EMAIL_REQUEST',
    CHECK_EMAIL_SUCCESS: 'CHECK_EMAIL_SUCCESS',
    CHECK_EMAIL_FAILURE: 'CHECK_EMAIL_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    CUSTOMER_REQUEST: 'CUSTOMER_REQUEST',
    CUSTOMER_SUCCESS: 'CUSTOMER_SUCCESS',
    CUSTOMER_FAILURE: 'CUSTOMER_FAILURE',

    NEW_PASSWORD_REQUEST: 'NEW_PASSWORD_REQUEST',
    NEW_PASSWORD_SUCCESS: 'NEW_PASSWORD_SUCCESS',
    NEW_PASSWORD_FAILURE: 'NEW_PASSWORD_FAILURE',

    DELETE_CUSTOMER: 'CUSTOMER_DELETE',
    LOGOUT: 'USERS_LOGOUT',
    CUSTOMER_RESET_MDP_RAZ: 'CUSTOMER_RESET_MDP_RAZ'
};

