import {trans_IT} from "./messages_IT";
import {trans_FR} from "./messages_FR";
import {sos_FR} from "./sos_FR";
import {sos_IT} from "./sos_IT";

export const transFiles = [
    {lang: "it", domain: "messages", file: trans_IT},
    {lang: "it", domain: "sos", file: sos_IT}
]

export const defaultTransFiles = [
    {lang: "fr", domain: "messages", file: trans_FR},
    {lang: "fr", domain: "sos", file: sos_FR}
]
