import {ReactComponent as IconSade} from './../../../asset/image/sosBreaking/picto-triste.svg';
import {ReactComponent as IconFatigue} from './../../../asset/image/sosBreaking/picto-fatigue.svg';
import {ReactComponent as IconAngry} from './../../../asset/image/sosBreaking/picto-colere.svg';
import {ReactComponent as IconOverflow} from './../../../asset/image/sosBreaking/picto-deborde.svg';
import {ReactComponent as IconDemotivation} from './../../../asset/image/sosBreaking/picto-demotive.svg';
import {ReactComponent as IconFrustation} from './../../../asset/image/sosBreaking/picto-frustration.svg';
import {ReactComponent as IconStress} from './../../../asset/image/sosBreaking/picto-stress.svg';
import {ReactComponent as IconAnxiety} from './../../../asset/image/sosBreaking/picto-angoisse.svg';
import {ReactComponent as IconHappy} from './../../../asset/image/sosBreaking/picto-heureux.svg';
import {ReactComponent as IconAlone} from './../../../asset/image/sosBreaking/picto-seul.svg';
import {trans} from "../../../translations/translations";

export const emotions = [
    {id: 1, text: trans('question3.helper.fatigue', 'sos'), icon: IconFatigue, order: 1, status: 'no-selected'},
    {id: 2, text: trans('question3.helper.alone', 'sos'), icon: IconAlone, order: 2, status: 'no-selected'},
    {id: 3, text: trans('question3.helper.frustration', 'sos'), icon: IconFrustation, order: 3, status: 'no-selected'},
    {id: 4, text: trans('question3.helper.motivation', 'sos'), icon: IconDemotivation, order: 4, status: 'no-selected'},
    {id: 5, text: trans('question3.helper.angry', 'sos'), icon: IconAngry, order: 5, status: 'no-selected'},
    {id: 6, text: trans('question3.helper.stress', 'sos'), icon: IconStress, order: 6, status: 'no-selected'},
    {id: 7, text: trans('question3.helper.sade', 'sos'), icon: IconSade, order: 7, status: 'no-selected'},
    {id: 8, text: trans('question3.helper.over', 'sos'), icon: IconOverflow, order: 8, status: 'no-selected'},
    {id: 9, text: trans('question3.helper.anxiety', 'sos'), icon: IconAnxiety, order: 9, status: 'no-selected'},
    {id: 10, text: trans('question3.helper.happy', 'sos'), icon: IconHappy, order: 10, status: 'no-selected'},
]
