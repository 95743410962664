import React from "react";
import {Button} from "react-bootstrap";
import '../../asset/css/Buttons/baseBtnMagenta.scss';

const BaseMagentaButton = (props) => {
    const { label, onClick } = props;

    return (
        <Button className="base-btn-magenta shadow-none text-uppercase px-3 py-md-2" onClick={onClick}>{label}</Button>
    )
}

export default BaseMagentaButton;