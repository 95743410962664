import React from "react";
import {useSelector} from "react-redux";
import {sosBreakingStep} from "../../_constants/sosBreakingConstants";
import ModalFormComponent from "./modalComponent/modalFormComponent";
import ModalDialogComponent from "./modalComponent/modalDialogComponent";
import '../../asset/css/SosBreaking/sosBreaking.scss';

const SosBreakingComponent = () => {

    const {openForm, openDialog, step} = useSelector(({sosBreakingReducer}) => sosBreakingReducer);

    return(
        <>
            {
                step !== sosBreakingStep.NO_BREAK && step !== sosBreakingStep.BIG_BREAK &&
                    <ModalFormComponent show={openForm} />
            }
            {
                step === sosBreakingStep.NO_BREAK &&
                    <ModalDialogComponent show={openDialog} />
            }
            {
                step === sosBreakingStep.BIG_BREAK &&
                    <ModalDialogComponent show={openDialog} />
            }
        </>
    )
}

export default SosBreakingComponent;