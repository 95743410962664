import {successConstants} from "../_constants";
// import {weightMock} from "../components/Success/Mock/weigthMock";

const initialState = {
    onMeasureRequest: false,
    measure: null,
    measureRequested: false,
    measureFailed: false
}

export const successReducer = ( state = initialState, action ) => {
    switch (action.type){
        case successConstants.MEASURE_GET_REQUEST:
            return{
                ...state,
                onMeasureRequest: true,
                measureRequested: false,
                measureFailed: false
            }
        case successConstants.MEASURE_GET_SUCCESS:
            return{
                ...state,
                onMeasureRequest: false,
                measureRequested: true,
                measureFailed: false,
                measure: action.measures
                // measure: weightMock
            }
        case successConstants.MEASURE_GET_FAILURE:
            return{
                ...state,
                onMeasureRequest: false,
                measureRequested: true,
                measureFailed: true,
                // measure: weightMock
            }
        default:
            return state;
    }
}