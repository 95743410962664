import React from "react";
import {Col, Image, Row} from "react-bootstrap";
import IconTick from "../../../asset/image/profile/tick-validation.svg";


const Question4InputComponent = (prop) => {

    const {text, status} = prop;

    return (
        <Row className={"no-gutters " + status}>
            <Col xs={"auto"} className={"check-context d-flex justify-content-center align-items-center pr-3"}>
                <div className={"check-case d-flex justify-content-center align-items-center"}>
                    {
                        status === 'selected' &&
                            <Image fluid src={IconTick} alt={"tick"} />
                    }
                </div>
            </Col>
            <Col xs={10} className={"text-context d-flex align-items-center"}>
                <p className={"m-0"}>{text}</p>
            </Col>
        </Row>
    )
}

export default Question4InputComponent;