import {sosBreakingConstants} from "../_constants/sosBreakingConstants";
import {sosBreakingServices} from "../_services/sosBreakingServices";

const sosBreakingStats = () => {
    return {type: sosBreakingConstants.SOS_STATS_SEND}
}

const sosBreakingReset = () => {
    return {type: sosBreakingConstants.SOS_RESET}
}

const sosBreakingOpenForm = () => {
    return {type: sosBreakingConstants.SOS_OPEN_FORM}
}

const sosBreakingOpenDialog = () => {
    return {type: sosBreakingConstants.SOS_OPEN_DIALOG}
}

const sosBreakingCloseForm = () => {
   return {type: sosBreakingConstants.SOS_CLOSE_FORM}
}

const sosBreakingCloseDialog = () => {
    return {type: sosBreakingConstants.SOS_CLOSE_DIALOG}
}

const sosBreakingUpStep = (step) => {
    return {type: sosBreakingConstants.SOS_UP_STEP, step}
}

const sosBreakingAddResponse = (response) => {
    return {type: sosBreakingConstants.SOS_UP_RESPONSE, response}
}

const sosBreakingSendEmail = (email) => {
    return dispatch => {
         sosBreakingServices.sendEmailSosBreaking(email)
            .then(
                dispatch(updateSosBreakingEmailStatus())
            )
    }
    function updateSosBreakingEmailStatus() { return { type: sosBreakingConstants.SOS_EMAIL_SEND}}
}

export const sosBreakingActions = {
    sosBreakingOpenForm,
    sosBreakingCloseForm,
    sosBreakingOpenDialog,
    sosBreakingCloseDialog,
    sosBreakingReset,
    sosBreakingUpStep,
    sosBreakingAddResponse,
    sosBreakingSendEmail,
    sosBreakingStats
}