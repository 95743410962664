import {profileConstants, profileMenu} from "../_constants";

const initialState = {
    isMenu: profileMenu.DATA,
    infosCustomer: null,
    infosCustomerRequested: false,
    onInfoCustomerProgress:false,
    onInfoCustomerUpdateProgress: false,
    infoCustomerUpdated: false,
    infoCustomerUpdateRequested: false
}

export const profileReducer = ( state = initialState, action ) => {
    switch (action.type){
        case profileConstants.CUSTOMER_UPDATE_RAZ:
            return {
                ...state,
                onInfoCustomerUpdateProgress: false,
                infoCustomerUpdateRequested: false,
                infoCustomerUpdated: false,
            }
        case profileConstants.CUSTOMER_UPDATE_REQUEST:
            return {
                ...state,
                onInfoCustomerUpdateProgress: true,
                infoCustomerUpdateRequested: false,
                infoCustomerUpdated: false,
            }
        case profileConstants.CUSTOMER_UPDATE_SUCCESS:
            return {
                ...state,
                onInfoCustomerUpdateProgress: false,
                infoCustomerUpdateRequested: true,
                infoCustomerUpdated: true,
            }
        case profileConstants.CUSTOMER_UPDATE_FAILURE:
            return {
                ...state,
                onInfoCustomerUpdateProgress: false,
                infoCustomerUpdateRequested: true,
                infoCustomerUpdated: false,
            }
        case profileConstants.INFOS_CUSTOMER_REQUEST:
            return {
                ...state,
                onInfoCustomerProgress: true,
                infosCustomerRequested: false,
                infosCustomer: null
            }
        case profileConstants.INFOS_CUSTOMER_SUCCESS:
            return {
                ...state,
                onInfoCustomerProgress: false,
                infosCustomerRequested: true,
                infosCustomer: action.infosCustomer
            }
        case profileConstants.INFOS_CUSTOMER_FAILURE:
            return {
                ...state,
                onInfoCustomerProgress: false,
                infosCustomerRequested: true
            }
        case profileConstants.SET_MENU:
            return {
                ...state,
                isMenu: action.menu
            }
        default:
            return state
    }
}