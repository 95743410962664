export const sosBreakingConstants = {
    SOS_OPEN_FORM: 'SOS_OPEN_FORM',
    SOS_OPEN_DIALOG: 'SOS_OPEN_DIALOG',
    SOS_CLOSE_FORM: 'SOS_CLOSE_FORM',
    SOS_CLOSE_DIALOG: 'SOS_CLOSE_DIALOG',
    SOS_UP_STEP: 'SOS_UP_STEP',
    SOS_UP_RESPONSE: 'SOS_UP_RESPONSE',
    SOS_RESET: 'SOS_RESET',
    SOS_EMAIL_SEND: 'SOS_EMAIL_SEND',
    SOS_STATS_SEND: 'SOS_STATS_SEND'
}

export const sosBreakingStep = {
    Q1: '1',
    Q2: '2',
    Q3: '3',
    Q4: '4',
    RESULT: '5',
    NO_BREAK: '6',
    BIG_BREAK: '7'
}

export const sosBreakingModalType = {
    FORM: 'form',
    DIALOG: 'dialog'
}