export const trans_IT = {
    pages: {
        connection: {
            title: "Connexion it",
            email: {
                title: "Votre adresse email it",
                subTitle: "Veuillez renseigner votre adresse email dans le champs ci-dessous pour accéder à votre espace it",
                placeholder: "Votre email it"
            },
        },
        profile: {
            title: "Mes réglages IT",
            menu: "Mes réglages IT",
        }
    }
}