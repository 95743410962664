import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import store from './App/_helpers/store';

import 'moment/locale/fr';
import 'moment/locale/it';

import App from './App/App';

ReactDOM.render(
      <Provider store={store}>
          <App />
      </Provider>,
  document.getElementById('root')
);
