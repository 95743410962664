import {refRules} from "../../_constants";

export const rulesTransformer = (rules) => {
    let newRules = []
    rules.forEach( (el) => {
      Object.entries(el).forEach(([k,v]) => {
            if(k === "ELEARNING" ){
                newRules.push({rule: refRules.el, status: v})
            }
        });
    })
    return newRules
}

export const setRules = (rules) => {
    return rules && rules.length > 0 ? rulesTransformer(rules) : [{rule: refRules.el, status: true}]
}