import {combineReducers} from 'redux';
import {authReducer} from "../_reducers/authReducer";
import {messageReducer} from "../_reducers/messageReducer";
import {customerReducer} from "../_reducers/customerReducer";
import {activityReducer} from "../_reducers/activitiesReducer";
import {profileReducer} from "../_reducers/profileReducer";
import {cmdReducer} from "../_reducers/cmdReducer";
import {coachingReducer} from "../_reducers/coachingReducer";
import {successReducer} from "../_reducers/successReducer";
import {sosBreakingReducer} from "../_reducers/sosBreakingReducer";

const rootReducer = combineReducers({
    authReducer,
    messageReducer,
    customerReducer,
    activityReducer,
    profileReducer,
    cmdReducer,
    coachingReducer,
    successReducer,
    sosBreakingReducer
})

export default rootReducer;