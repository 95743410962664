import {Card, Col} from "react-bootstrap";
import React from "react";

const Question3IconComponent = (prop) => {

    const {Icon, text, status} = prop;

    return(
        <Card className={"mb-3 " + status }>
            <Card.Body className="p-2">
                <Col xs={12} className="wrap-img mb-2 d-flex justify-content-center">
                    <Icon />
                </Col>
                <Col xs={12} className="wrap-text p-0">
                    <Card.Text className="text-theme text-center">{text}</Card.Text>
                </Col>
            </Card.Body>
        </Card>
    )
}

export default Question3IconComponent;