import {refBrand} from "../../_constants";

export const buildListItem = (itemBrands, brands, rules = null ) => {
    let items = handleBrand(itemBrands, brands)
    if(rules){
        items = handleRule(items, rules)
    }
    return items
}

const handleRule = (listItems, rules) => {
    let newListItems = []
    listItems.forEach((el) => {
        if (el.rules === undefined) {
            newListItems.push(el)
        }else if(el.rules.some(v => reduceArrayRules(rules).indexOf(v) !== -1)){
            newListItems.push(el)
        }
    })
    return newListItems
}

const handleBrand = (listItem, brands) => {
    let newListItems = []
    listItem.forEach((el) => {
        if (el.brand.some(v => reduceArrayBrand(brands).indexOf(v) !== -1)  && el.access ) {
            newListItems.push(el)
        }
    })
    return newListItems
}

export const reduceArrayRules = (rules) => {
    return rules.map((el) => {
        const {rule, status} = el;
        return status ? rule : false
    })
}

export const reduceArrayBrand = (brands) => {
    return brands.map(el => el.externalId)
}

export const isInBrand = (brands) => {
    let ref = false;
    Object.values(refBrand).forEach((el) => {
        if(reduceArrayBrand(brands).indexOf(el) !== -1){
            ref =  true
        }
    })
    return ref
}

export const setBrand = (brands) => {
    return brands.length > 0 && isInBrand(brands) ? brands : [{externalId: refBrand.cja, name: "COMMEJAIME"}]
}