import React from "react";
import {Modal} from "react-bootstrap";
import BaseMagentaButton from "../../Button/baseMagentaBtn";
import {trans} from "../../../translations/translations";

const ModalEmailConfirmationComponent = (props) => {

    const {show, handleClose } = props;

    return(
        <Modal className="email-modal email-modal-success" show={show} onHide={handleClose}>
            <Modal.Body className="body-email-success body-modal-text text-center">
                <p>{trans('email.conf', 'sos')}</p>
                <BaseMagentaButton label={trans('btn.close', 'sos')} onClick={handleClose} />
            </Modal.Body>
        </Modal>
    )
}

export default ModalEmailConfirmationComponent;