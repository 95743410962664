import {emotions} from "./modalContentComponent/question3Helper";
import {contexts} from "./modalContentComponent/question4Helpers";

export const emailFactoryComponent = (response) => {
    const infos  = JSON.parse(sessionStorage.getItem("infos"));
    const form = new FormData();
    let message = infos.firstname + " a craqué ! Merci de faire rappeler ce client par son coach sous 24 heures ! <br><br>"
    message += Q(response);

    form.append('subject', 'SOS CRAQUAGE Un client a craqué !');
    form.append('message', message);
    form.append('customerId', infos.perfectId);

    return form
}

const Q = (response) => {

    let questions = "<dl><dt>Données formulaires : <dd><dl>"

     response.forEach((el) => {

        if( el.R === 1 && el.value === 1){
            questions += "<dt>Besoin :</dt><dd>La faim</dd>";
        }else if( el.R === 1 && el.value === 2 ){
            questions += "<dt>Besoin :</dt><dd>L'envie</dd>";
        }

        if( el.R === 2 && el.value === 1){
            questions += "<dt>Intensité :</dt><dd>1</dd>";
        }else if( el.R === 2 && el.value === 2 ){
            questions += "<dt>Intensité :</dt><dd>2</dd>";
        }else if( el.R === 2 && el.value === 3 ){
            questions += "<dt>Intensité :</dt><dd>3</dd>";
        }

        if( el.R === 3 ){
            let listEmotions = "<dt>Emotion(s)</dt>"
            el.value.forEach((emotion, id) => {
                listEmotions += "<dd> - " + emotions.filter(el => el.id === emotion)[0].text + "</dd>";
            } )
            questions +=  listEmotions;
        }

         if( el.R === 4 ){
             let listContexts = "<dt>Context(s)</dt>"
             el.value.forEach((context, id) => {
                 listContexts += "<dd> - " + contexts.filter(el => el.id === context)[0].text + "</dd>";
             } )
             questions += listContexts;
         }
    })
    questions += "</dl></dd></dt></dl>"
    return questions;
}

