import React from "react";
import {Col, Row} from "react-bootstrap";
import BasePinkButton from "../../Button/basePinkBtn";
import {trans} from "../../../translations/translations";

const BigBreakComponent = (props) => {

    const {handleCloseDialog, handleEmail} = props;
    return(
        <Row className={"no-break-row justify-content-center pb-4"}>
            <Col xs={12} className={"text-center title mb-4"}>
                <span>{trans('break.text1', 'sos')}</span>
            </Col>
            <Col xs={10} className={"text-center text-dialog mb-3"} >
                <p className={"m-0"}>{trans('break.text2', 'sos')}</p>
                            </Col>
            <Col xs={10}  className={"text-center text-dialog mb-3"} >
                <p className={"m-0"}>{trans('break.text3', 'sos')}</p>
            </Col>
            <Col xs={10}  className={"text-center text-dialog mb-3 mb-sm-4"} >
                <p className={"m-0"}>{trans('break.text4', 'sos')}</p>
            </Col>
            <Col xs={12} sm={4} className={"pb-3 pb-sm-0 d-flex align-items-center justify-content-center px-0"}>
                <p className={"m-0 text-link"} onClick={handleCloseDialog}>{trans('btn.close', 'sos')}</p>
            </Col>
            <Col xs={12} sm={8} className={"d-flex align-items-center justify-content-center px-0"}>
                <BasePinkButton label={trans('btn.callback', 'sos')} onClick={handleEmail}/>
            </Col>
        </Row>
    )
}

export default BigBreakComponent;