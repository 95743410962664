export const sos_FR = {
    slug: "sos",
    title: "SOS CRAQUAGE",
    description: " Pour être accompagné.e quand je suis sur le point de craquer.",
    btn: {
        prevQuestion: "Question précédente",
        nextQuestion: "Question suivante",
        breakDown: "J'ai craqué",
        sendSos: "Envoyer cet SOS",
        angryOk: "La faim m'est passée",
        desireOk: "L'envie m'est passée",
        close: "Fermer",
        callback: "être rappellé.e par mon coach"
    },
    questions: {
        text1: "Vous êtes sur le point de craquer et de faire un écart ?",
        text2: "Nous sommes là pour vous accompagner !",
        text3: "Comprendre l'origine d'un «craquage» est incontournable pour y faire face.",
        text4: "Pour ce faire, nous allons vous poser 4 questions afin de vous aider à résister du mieux que l'on peut !",
    },
    question1: {
        title: "Question 1/4",
        text1: "À quel",
        text2: "besoin",
        text3: "répond votre craquage ?",
        description: "La faim correspond à un besoin physiologique du corps. Elle se développe petit à petit, vous pouvez patienter avant de la satisfaire. L'envie correspond à un besoin émotionnel, elle ne prend pas en considération les sensations de faim, de rassasiemment ou de satiété.",
        helper: {
            hunger: "La faim",
            desire: "L'envie",
        },
    },
    question2: {
        title: "Question 2/4",
        text1: "Sur une échelle de 1 à 3, comment évaluez-vous",
        anger: "faim",
        desire: "envie",
        text2: "le niveau de votre",
        low: "Juste un petit peu",
        high: "Insoutenable"
    },
    question3: {
        title: "Question 3/4",
        text1: "Sélectionnez la ou",
        text2: "les émotions que vous ressentez :",
        helper: {
            fatigue: "Fatigué.e",
            alone: "Seul.e",
            frustration: "Frustré.e",
            motivation: "Démotivé.e",
            angry: "En colère",
            stress: "Stressé.e",
            sade: "Triste",
            over: "Débordé.e",
            anxiety: "Angoissé.e",
            happy: "Heureux.se",
        },
    },
    question4: {
        title: "Question 4/4",
        text1: "Cochez le ou",
        text2: "les contextes",
        text3: "dans lesquels vous vous trouvez :",
        helper: {
            meal: "Je prépare le repas",
            table: "Je sors de table",
            work: "Je travaille",
            boring: "Je m'ennuie",
            holidays: "Je suis en vacances",
            bad: "J'ai passé une mauvaise journée",
            loss: "Je ne perds pas de poids",
            screen: "Je suis devant les écrans",
            sleep: "Je dors mal",
        },
    },
    noBreak: {
        text1: "Félicitations !",
        text2: "Vous avez surmonté votre envie.",
        text3: "Continuez comme ça, vous êtes sur la bonne voie !",
        text4: "Chacune de vos réussites contribue à vous rendre plus fort.e pour faire face à vos nouvelles envies de craquage.",
        text5: "Nous vous renouvellons nos félicitations !",
    },
    break: {
        text1: "Courage !",
        text2: "Les écarts font parfois partie du processus et les surmonter n'est qu'une question d'entraînement. L'essentiel est de comprendre les situations qui en sont à l'origine afin de pouvoir les modifier.",
        text3: "N'hésitez pas à cliquer sur le bouton ci-dessous pour être rappelé.e par le coach afin d'en parler.",
        text4: "Quoi qu'il en soit, restez motivé.e !",
    },
    results: {
        text1: "Afin de mettre toutes les chances de votre côté, voici les conseils que nous vous recommandons de suivre lorsque vous êtes sur le point de craquer dans ce genre de situation",
        answer1: {
            desire: {
                advice1: {
                    title: {
                        text1: "Analyser",
                        text2: "l'origine de ses envies.",
                    },
                    sub: {
                        text1: "Bravo ! Vous avez su distinguer la faim et l'envie, ce qui est déjà très positif.",
                        text2: "Si l'envie est parfois liée à la faim, elle est plus souvent la traduction d'une émotion ou d'une situation agréable / désagréable.",
                        text3: "Ne comptez pas sur nous pour vous dire de manger une carotte plutôt qu'un carré de chocolat car le but n'est pas de vous frustrer mais de travailler avec vous sur l’origine de cette envie.",
                        text4: "Lorsque l'envie est là, accueillez-la sans la juger. Analysez ce qui s'est produit dans votre esprit ou dans votre environnement pour la générer. Quelles émotions avez-vous ressenties ? Quel besoin pensez-vous combler en mangeant ? Par quel alimentait souhaiteriez-vous combler cette envie ?",
                        text5: "Prenez le temps de comprendre en toute bienveillance les situations dans lesquelles apparaît ce genre d'envie. Pour cela, n'hésitez pas à tenir un carnet afin de noter votre analyse à chaque fois qu'une envie de manger surgit.",
                        text6: "C'est en comprenant ces mécanismes que vous serez en mesure d'adapter naturellement votre comportement alimentaire à la situation sans même ressentir de frustration."
                    },
                },
                advice2: {
                    title: {
                        text1: "Vous ressentez l'",
                        text2: "envie de manger",
                        text3: "un aliment que vous aimez",
                        text4: "?",
                    },
                    sub: {
                        text1: "Une envie ou un écart ne doit en aucun cas vous culpabiliser. Il n'y a pas d'aliments interdits mais seulement des aliments plus riches à prendre de façon plus modérée que d'autres, voire de façon exceptionnelle.",
                        text2: "Le rassasiement est aussi lié au plaisir de manger et si vous avez envie de prendre un chocolat avec votre café, vous pouvez vous l'autoriser pour vous éviterez ainsi de créer une frustration trop importante.",
                        text3: "L'équilibre alimentaire se faisant sur une semaine, reprenez sereinement le déroulé de votre programme.",
                    },
                },
                advice3: {
                    title: {
                        text1: "L'aliment",
                        text2: "Doudou",
                    },
                    sub: {
                        text1: "Le plus souvent gras, très sucrés ou très salés, les aliments \"doudous\" nous donnent l'impression d'anesthésier nos émotions. Ainsi, manger calme nos angoisses ou notre stress, comble l’ennui ou la solitude ou nous fait momentanément oublier nos petites contrariétés.",
                        text2: "Dans ces situations, ne repoussez pas vos émotions. Au contraire, accueillez-les et prenez quelques instants pour respirer lentement. Puis, si l'envie persiste, prenez votre aliment plaisir, sentez-le, touchez-le et dégustez-le lentement en décrivant la texture molle ou craquante et les saveurs qu'il renferme. Faites de cet instant un vrai plaisir en le dégustant en pleine conscience et surtout : ne culpabilisez pas.",
                    },
                },
                advice4: {
                    title: {
                        text1: "Vous avez fait",
                        text2: "un écart",
                        text3: "et depuis vous avez",
                        text4: "envie de manger",
                        text5: "?",
                    },
                    sub: {
                        text1: "Vous avez craqué une fois sur un aliment trop gras, trop sucré ou trop salé et depuis, vous avez l'impression que les écarts s'enchaînent ? Premièrement : ne culpabilisez pas ! L'être humain n'est pas un robot et il est tout à fait normal de ressentir des envies. Aucun aliment n'est interdit, ni à diaboliser même pendant la phase de perte de poids, il faut juste veiller à en consommer certains de manière exceptionnelle.",
                        text2: "Lorsque vous craquez, si vous culpabilisez, si vous vous blâmez ou si vous vous réprimez il y a de grandes chances que la frustration ou la colère vous poussent à craquer davantage, vous amenant ainsi vers le cercle vicieux du \"Au point où j'en suis...\".",
                        text3: "C'est pourquoi, il est primordial que vous cultiviez la bienveillance et la tolérance envers vous-même. Acceptez vos moments de doutes et de craquages et surtout, essayez de les comprendre sans émettre de jugements.",
                    },
                },
                advice5: {
                    title: {
                        text1: "Vous ressentez de la",
                        text2: "frustration",
                        text3: "?",
                    },
                    sub: {
                        text1: "Aimez-vous les plats que vous mangez ? Certains aliments vous manquent-ils ? Avez-vous l'impression e vous priver ?",
                        text2: "En répondant à ces questions, vous pouvez observer si vous ressentez de la frustration. Et si c'est le cas, cela pourrait expliquer que vous mangiez plus que de raison",
                        text3: "Pour éviter le sentiment de frustration, accordez-vous un plaisir de temps en temps. Rien n'est interdit. Pensez à votre objectif certes, mais cultivez aussi des plaisirs ponctuels ! L'équilibre nutritionnel se faisant sur une semaine, il n'est donc pas interdit de manger ce qui vous fait du bien tant que c'est fait en pleine conscience et à l'écoute de vos sensations alimentaires : sachez vous arrêter dès que vous n'avez plus faim et adaptez votre repas suivant en fonction.",
                    },
                },
            },
            hunger: {
                advice1: {
                    title: {
                        text1: "Vous avez",
                        text2: "sauté un repas",
                        text3: "ou vous avez",
                        text4: "un petit creux",
                        text5: "?",
                    },
                    sub: {
                        text1: "Être affamé.e est le signe que vous n'avez pas apporté l'énergie nécessaire à votre corps au cours du ou des précédents repas. Et cela génère bien souvent un besoin de manger très vite et en grande quantité. Voici quelques astuces pour ne sauter aucun repas et apporter à votre corps tout ce dont il a besoin pour ne pas se sentir affamé :",
                        breakfast: {
                            title: "Le petit déjeuner",
                            sub: "Si vous n'avez pas faim le matin, fractionnez-le en 2 temps. (une moitié à 8h, une moitié à 10h par exemple).",
                        },
                        lunch: {
                          title: "Le déjeuner",
                          sub : "Si vous n'avez pas le temps et que vous n'avez pas votre plat COMME J'AIME avec vous, vous pouvez tout à fait manger sur le pouce de manière exceptionnelle en prenant un sandwich poulet crudités sans mayonnaise avec un fruit, comme une pomme.",
                        },
                        collation: {
                            title: "La collation",
                            sub: "Si vous ressentez juste un petit creux ou une faim dans la journée, n'hésitez pas à prendre une portion de protéines pour vous caler jusqu'au repas suivant : 1 tranche de volaille (jambon, poulet, dinde), 1 fromage blanc sans sucre et 1 fruit (1 pomme, 1 banane).",
                        },
                        diner: {
                            title: "Le soir",
                            sub: "Pensez bien à prendre votre dîner comme indiqué sur votre menu et surtout, ne sautez pas ce repas pour ne pas vous réveiller affamé.e le lendemain matin.",
                        }
                    },
                },
                advice2: {
                    title: {
                        text1: "Vous ne prenez pas",
                        text2: "votre collation",
                        text3: "?",
                    },
                    sub: {
                        text1: "Certaines personnes se satisfont tout à fait de 3 repas principaux par jour et certaines autres, ressentent le besoin de manger une collation. Cela peut dépendre du métabolisme, de l'heure des repas, de la sensation de faim et des besoins énergétiques de chacun.",
                        text2: "Concrètement, la collation permet : ",
                        text3: "d'assurer un apport d’énergie constant tout au long de la journée (surtout en cas d’activité physique),",
                        text4: "d'éviter les fringales et les grignotages,",
                        text5: "de couvrir les besoins énergétiques chez certaines personnes pour qui 3 repas ne suffisent pas,",
                        text6: "d'éviter la fatigue liée au besoin de manger,",
                        text7: "d'éviter la frustration qui a tendance à pousser vers des aliments trop gras ou trop sucrés.",
                        text8: "Prendre une collation ne signifie par grignoter, mais prendre un mini repas sain, de manière encadrée. Si vous n'avez pas l'habitude de prendre votre fruit ou votre collation COMME J'AIME, réintégrez-la dans votre quotidien. Vous verrez vos fringales s'amenuiser.",
                    },
                },
                advice3: {
                    title: {
                        text1: "Vous",
                        text2: "suivez bien le programme",
                        text3: "et pourtant,",
                        text4: "Vous avez encore faim",
                        text5: "?",
                    },
                    sub: {
                        text1: "Il arrive que certaines personnes, bien qu'elles suivent leur programme à la lettre, ressentent des fringales au cours de la journée. Si c'est votre cas, c'est peut-être que vous ne prêtez pas assez attention à la manière dont vous mangez au moment des repas.",
                        text2: "Pour atteindre la satiété vous devez vous consacrer pleinement à votre repas et être à l'écoute de votre corps. Cela passe par le fait de manger dans un endroit calme, assis.e, sans éléments perturbateurs. Évitez donc les écrans, ne travaillez pas en mangeant, ne réfléchissez pas à ce que vous devrez faire après... Bref, faites de ce moment, un instant privilégié de sérénité et de dégustation, uniquement dédié à votre repas.",
                        text3: "Fait important : Il faut 15 à 30 minutes à l'organisme pour ressentir la sensation de rassasiement. C'est pourquoi il est primordial que vous preniez le temps de manger. Mastiquez bien vos aliments et attendez d'avoir fini votre bouchée pour prendre la suivante. Ainsi en mangeant moins vite, vous serez tout simplement plus à l'écoute de vos sensations alimentaires.",
                    },
                },
                advice4: {
                    title: {
                        text1: "Vous avez",
                        text2: "repris le sport",
                        text3: "et ne comprenez pas pourquoi vous avez",
                        text4: "très faim",
                        text5: "?",
                    },
                    sub: {
                        text1: "Si vous avez commencé ou repris un sport d'endurance dernièrement, il est tout à fait normal que vous ressentiez de la faim après car votre corps consomme plus d'énergie.",
                        text2: "Après l'effort, buvez un grand verre d'eau et pour bien récupérer ajoutez au choix :",
                        text3: "1 tranche de jambon ou de blanc de poulet,",
                        text4: "1 fromage blanc ou 1 yaourt,",
                        text5: "1 fruit entier (1 orange ou 1 petite banane) + 5 amandes, 5 noisettes, 1 noix,",
                        text6: "1 barre céréalière COMME J'AIME,",
                        text7: "1 verre de lait avec du cacao maigre.",
                        or: "ou",
                    },
                },
                advice5: {
                    title: {
                        text1: "Vous n'avez pas l'habitude d'ajouter des",
                        text2: "produits frais",
                        text3: "à votre programme ?",
                    },
                    sub: {
                        text1: "Ajouter des légumes à vos repas n'est pas obligatoire, mais fortement conseillé, surtout si vous ressentez régulièrement de la faim au cours de la journée. Les légumes ont un faible apport calorique mais un fort pouvoir rassasiant ! En entrée, ou en accompagnement de votre plat COMME J'AIME, vous verrez vite la différence en les intégrant dans vos menus.",
                        text2: "Pensez également à ajouter à vos repas les fruits et produits laitiers conseillés. D'ailleurs, si vous en ressentez le besoin, vous pouvez tout à fait remplacer la compote du midi par un fruit entier.",
                        text3: "Pour plus de détails, n'hésitez pas à vous référer à votre livret de bienvenue.",
                    },
                },
                advice6: {
                    title: {
                        text1: "Quand la",
                        text2: "faim",
                        text3: "et la",
                        text4: "soif",
                        text5: "sont",
                        text6: "confondues"
                    },
                    sub: {
                        text1: "Peut-être connaissez-vous l'adage \"La faim est une soif qui s'ignore\" ?",
                        text2: "En effet, cette citation décrit une confusion qui se produit couramment : confondre la sensation de faim et la sensation de soif. Ainsi, quand la sensation de faim se fait sentir, commencez par vous demander à quand date le dernier verre d'eau que vous avez bu. Puis, servez-vous en un, attendez quelques minutes. Si la sensation disparaît, c'est que vous aviez simplement soif. Si elle persiste, c'est qu'il s'agit bien de la faim. Dans ce cas, accordez vous une petite collation saine ( 1 tranche de volaille (jambon, poulet, dinde), 1 fromage blanc sans sucre et 1 fruit (1 pomme, 1 banane).",
                    },
                },
            },
        },
        answer2: {},
        answer3: {
            alone: {
                advice1: {
                    title: {
                        text1: "Quand manger compense",
                        text2: "le sentiment de solitude",
                    },
                    sub: {
                        text1: "La nourriture intervient parfois comme une solution instantanée pour compenser le sentiment de solitude, mais ce n'est pas une fatalité !",
                        text2: "Dans l'immédiat, sortez prendre l'air, marchez un peu, mettez une chanson qui vous donne la pêche, appelez un.e ami.e, regardez un film qui vous évite d'avoir des pensées négatives ou encore, écrivez dans un journal tout ce que vous avez sur le cœur !",
                        text3: "De manière plus générale, organisez les moments où vous vous retrouvez tout.e seul.e en déterminant un jour et une heure pour passer à l'action ! Les moments en solitaires peuvent être très bénéfique pour réveiller vos talents cachés : le chant, la peinture, la sculpture, la couture, le bricolage, le jardinage ....",
                    },
                },
                advice2: {
                    title: {
                        text1: "Se reconnecter à soi-même",
                        text2: "pour ne plus se sentir seul.e",
                    },
                    sub: {
                        text1: "L'isolement et le repli sur soi peuvent être facteurs de d'altération de l'estime de soi et engendrer, entre autre, du grignotage. La bonne nouvelle, c'est que le corps a la capacité extraordinaire de rétablir la confiance en soi et de vous faire prendre conscience de toutes vos richesses intérieures. Plusieurs méthodes peuvent vous y aider. La sophrologie, la relaxation, le yoga ou la danse, par exemple. En pratiquant régulièrement l'une de ces activités, vous apprendrez petit à petit à découvrir chaque partie infime de votre corps et votre estime de vous ira en grandissant.",
                        text2: "En parallèle, prenez le temps de lister vos compétences et talents divers (des plus petits aux plus importants) et relisez régulièrement votre liste pour rebooster votre confiance en vous.",
                    },
                },
                advice3: {
                    title: {
                        text1: "Être seul : l'opportunité de prendre",
                        text2: "du temps pour soi",
                    },
                    sub: {
                        text1: "La solitude appelle parfois la prise alimentaire pour compenser le manque de contacts humain. Cela peut ainsi générer une boucle infernale de manque d’estime de soi qui entretient la solitude et ainsi de suite.",
                        text2: "Il est donc essentiel de prendre soin de vous tant sur le plan physique que sur le plan émotionnel pour pouvoir renouer avec les autres.",
                        text3: "Commencez par voir le côté positif de la solitude : pleins de gens rêvent d'avoir des moments seuls, pour profiter de prendre soin d’eux. C'est l'occasion pour vous de vous chouchouter, de pratiquer l’activité physique, sportive ou artistique qui vous fait du bien.",
                        text4: "Faites la liste des choses qui peuvent vous procurer du bien-être au quotidien. Privilégiez les petits plaisirs facilement négligés sous prétexte qu’ils ne changeront pas votre vie. Mis bout à bout, ils ne peuvent que vous apporter des journées sereines et épanouissantes.",
                    },
                },
                advice4: {
                    title: {
                        text1: "Comprendre",
                        text2: "son sentiment de solitude"
                    },
                    sub: {
                        text1: "Quand le sentiment de solitude vous pousse à grignoter, demandez-vous quelle est l'origine de ce sentiment et quel besoin vous essayez de combler.",
                        text2: "S'agit-il d'un manque de liens sociaux lié au chômage, au télétravail, à un déménagement,.. ?",
                        text3: "S'agit-il d'un manque de liens affectifs lié à une rupture douloureuse, à un éloignement familial, à une dispute amicale,... ?",
                        text4: "Cette analyse vous permettra d'y voir plus clair dans vos attentes et d'agir en conséquence. Elle vous permettra également de ne pas subir votre solitude, qui sur le long terme peut générer un sentiment d'impuissance et étouffer toute votre volonté d'agir selon vos envies profondes.",
                    },
                },
                advice5: {
                    title: {
                        text1: "Un",
                        text2: "sommeil de qualité",
                        text3: "pour se reconnecter à soi et aux autres",
                    },
                    sub: {
                        text1: "De prime abord, rien ne laisse penser qu'il y a un lien entre le sommeil et le sentiment de solitude... Et pourtant ! Le manque de sommeil ou un sommeil de mauvaise qualité semble avoir effet « désocialisant ». Non seulement la fatigue liée au sommeil génère une humeur et des émotions négatives (dissuadant ainsi les autres de nouer des contacts avec soi), mais elle pousse également au repli sur soi et au grignotage.",
                        text2: "Veillez donc à optimiser la durée et la qualité de votre sommeil tant pour vous reconnecter à votre corps, qu'aux autres.",
                    },
                },
                advice6: {
                    title: {
                        text1: "Remettre du",
                        text2: "positivisme",
                        text3: "dans sa vie pour lutter contre le sentiment de solitude",
                    },
                    sub: {
                        text1: "Le sentiment de solitude peut entraîner plusieurs comportements : du grignotage, du jugement, de la négativité... L'objectif est donc de remettre du positif dans votre vie !",
                        text2: "Vous qui avez lancé \"SOS craquage\", profitez de ce moment pour pratiquer un exercice de projection : fermez les yeux et visualisez un endroit qui vous remplit de bonheur ou qui vous procure du bien-être. Réveillez de jolis ou amusants souvenirs et remémorez les en détail. Où était-ce ? Quand ? Avec qui ?",
                        text3: "Dans le prolongement de cet exercice, prenez l'habitude, chaque soir, de lister 3 petits moments qui auront été faciles, agréables ou enrichissants au cours de votre journée. Attardez-vous sur chacun d'eux en les revivant et en exprimant de la gratitude. Au fur et à mesure, vous retrouverez votre bienveillance et votre positivisme. Et peu à peu, le besoin de combler la solitude par l'alimentation s'atténuera.",
                    },
                },
                advice7: {
                    title: {
                        text1: "Prendre soin des autres",
                        text2: "pour lutter contre le sentiment de solitude",
                    },
                    sub: {
                        text1: " Vous ressentez un sentiment de solitude et ne savez pas comment le combler autrement que par la nourriture ? Sachez que vous n'êtes pas seul.e dans ce cas, et que de nombreuses personnes sont en manque de contact humain.",
                        text2: "C'est pourquoi, vous pourriez prendre le problème à l'envers en vous demandant comment vous pourriez aider et soutenir les autres. Il peut s'agir de vous proposer pour garder des enfants, faire du bénévolat, rendre visite à des personnes âgées...",
                        text3: "C'est aussi valable pour les animaux. Si vous les appréciez, pourquoi ne pas en adopter un pour lui donner tout l'amour et la tendresse que vous avez en vous ?",
                        text4: "Par le sentiment de sérénité qu’ils procurent aux humains, les animaux de compagnie sont connus pour leur capacité à redonner le goût de la vie, du plaisir et du contact avec les autres.",
                        text5: "Alors ? Avec qui ou avec quel animal allez-vous partager de belles interactions ?",
                    },
                },
                advice8: {
                    title: {
                        text1: "Avoir",
                        text2: "un doudou",
                        text3: "même adulte",
                    },
                    sub: {
                        text1: "Qui a dit que seuls les enfants pouvaient avoir un doudou ?",
                        text2: "Si celui-ci permet à l'enfant de faire la transition entre la maison et l'extérieur, il représente un point d'ancrage pour les adultes qui en possèdent un.",
                        text3: "De nombreux adultes possèdent un doudou dont la présence les rassure dans des moments de solitude ou de tristesse. Ils incarnent de vrais \"objets de vie\" aux puissantes vertus sociales : ils ne jugent pas, sont à l'écoute et sont toujours présents.",
                        text4: "Alors n'hésitez pas vous-même à adopter votre doudou qui saura vous accompagner quand vous serez sur le point de craquer...",
                    },
                },
            },
            angry: {
                advice1: {
                    title: {
                        text1: "Extérioriser",
                        text2: "sa colère",
                    },
                    sub: {
                        text1: "La colère pousse parfois à réagir de manière impulsive, ce qui peut engendrer de mauvaises décisions, telles que le grignotage.",
                        text2: "Considérez la colère comme de l'énergie et non comme de l'agressivité. Accueillez cette émotion envahissante sans chercher à la calmer par la nourriture. La colère permet d'évacuer les tensions internes, d'exprimer votre ressenti face à une situation ...",
                        text3: "Prenez un papier et un stylo et décrivez ce que vous ressentez exactement. Notez en détail ce qui l'a causée, puis estimez son importance sur une échelle de 0 à 5 afin de prendre du recul vis à vis d'elle. N'hésitez pas à changer de point de vue pour essayer de comprendre la personne ou le fait d'un autre prisme.",
                        text4: "Et surtout, n'hésitez pas à en parler à une personne de confiance.",
                    },
                },
                advice2: {
                    title: {
                        text1: "Evacuer la colère par",
                        text2: "la respiration abdominale",
                    },
                    sub: {
                        text1: "La colère permet d'évacuer des tensions internes très fortes. Physiquement, elle entraîne une augmentation du rythme cardiaque et de la pression artérielle. C'est pourquoi la respiration abdominale est un bon moyen pour se détendre et réguler les battements du cœur. Debout, assis.e ou en position allongée, posez vos mains sur votre ventre, prenez une grande inspiration par le nez en gonflant lentement le ventre puis expirez très doucement par la bouche tout l'air, en rentrant le ventre. Faites-le 10 fois, à chaque fois que vous en ressentez le besoin."
                    },
                },
                advice3: {
                    title: {
                        text1: "Extérioriser la colère par",
                        text2: "l'activité physique",
                    },
                    sub: {
                        text1: "La colère libère une énergie qui nous aide à surmonter des obstacles, à défendre notre territoire et nos valeurs. Cette énergie se manifeste en cas de frustration ou d'insatisfaction. Pour évacuer sainement cette énergie, rien de mieux que de pratiquer une activité physique. Cela permet à votre organisme de sécréter de la sérotonine et des endorphines (les hormones du bien être).",
                        text2: "Faites ce qui vous plaît : de la marche, du stretching, de la course à pieds, de la musculation, du yoga, ... 10 mn suffisent pour vous permettre de vous défouler et de détendre vos muscles.",
                    },
                },
                advice4: {
                    title: {
                        text1: "Exprimer sa colère avec",
                        text2: "des mots",
                    },
                    sub: {
                        text1: "Un fait ou une personne a généré la colère que vous ressentez.",
                        text2: "Dans ce cas, pourquoi ne pas rédiger une lettre ? Vous ne l'enverrez pas, mais au moins, vous y aurez déversé vos ressentis.",
                        text3: "Rédigez-la sans filtre, exposez ce que vous reprochez à la personne qui est à l'origine de votre colère, ce que les faits vous ont fait ressentir, et pourquoi. Vous défouler par écrit vous permettra de passer plus facilement à autre chose et de mieux comprendre la raison d'être de votre émotion. Cela vous évitera également de prononcer des paroles que vous pourriez regretter.",
                    },
                },
                advice5: {
                    title: {
                        text1: "Remplacer la colère par",
                        text2: "une autre émotion",
                    },
                    sub: {
                        text1: "L'une des solutions pour évacuer la colère sans culpabiliser, c'est de remplacer cette émotion par une autre.",
                        text2: "Comment ? En prenant du temps pour vous. Faites-quelque chose qui vous fait plaisir : marcher, prendre un bain, écouter une musique agréable, dessiner, lire, regarder un film... Toutes les solutions sont bonnes tant qu'elles sont saines. Le but étant de détourner votre attention du sentiment de colère pour repartir du bon pied. Alors ? Vers quelle activité allez-vous vous tourner ?",
                    },
                },
                advice6: {
                    title: {
                        text1: "Se recentrer sur",
                        text2: "ses 5 sens",
                    },
                    sub: {
                        text1: "Un des moyens d'apaiser la colère est de faire marcher ses 5 sens :",
                        text2: "Le toucher : Tapez dans un oreiller pour vous défouler",
                        text3: "La vue : Regardez une photo que vous aimez bien ou une vidéo amusante, sortez prendre l'air et laisser votre regard apprécier les détails de la nature...",
                        text4: "L'ouïe : Écoutez une musique on un son que vous aimez, sortez apprécier le chant des oiseaux, appelez un.e ami.e dont la voix est apaisante...",
                        text5: "L'odorat : Inhalez une huile essentielle, un parfum ou une odeur qui rassure...",
                        text6: "Le goût : Buvez une tisane",
                    },
                },
            },
            anxiety: {
                advice1: {
                    title: {
                        text1: "Accepter",
                        text2: "et",
                        text3: "exprimer",
                        text4: "ses angoisses",
                    },
                    sub: {
                        text1: "Reconnaître votre angoisse est un bon point de départ pour avancer. La solution ne se trouve pas dans l'alimentation mais dans la compréhension de vos propres ressentis.",
                        text2: "Prenez un papier et un stylo et notez en détail ce que vous éprouvez, ce que provoque en vous vos angoisses et comment elles se manifestent. Lorsque cette émotion arrive, réfléchissez si vous avez souffert d’un évènement qui a pu altérer votre niveau émotionnel (travail, amis, famille) et plongez vous dans votre passé pour déterminer les causes de ces angoisses. Identifiez la ou les situations afin d'éviter de \"manger vos émotions\" à tout prix. En repérant et en analysant tous les tenants et aboutissants de vos angoisses, vous serez en mesure de les apprivoiser.",
                        text3: "N'hésitez pas à appeler une personne proche, de confiance, afin de partager avec elle vos ressentis, ou même à consulter un médecin. Un avis extérieur pourra être salvateur.",
                    },
                },
                advice2: {
                    title: {
                        text1: "Prendre le temps de",
                        text2: "faire une pause",
                    },
                    sub: {
                        text1: "Lorsque nous sommes angoissés, nous avons tendance à nous tourner vers la nourriture dans le but de nous détendre et de trouver du réconfort. Mais cela peut vite avoir un impact sur votre poids. C'est pourquoi, il est donc important de trouver une autre source de réconfort.",
                        text2: "Prenez le temps de vous relaxer, ne serait-ce que 10 ou 15 mn. Faites le vide et travaillez sur votre respiration en inspirant et en expirant lentement.",
                        text3: "Vous pouvez également écouter de la musique, ou vaquer à une occupation qui vous plaît et qui vous permet de relâcher la pression. Une fois détendu.e, le besoin de manger s'estompera.",
                        text4: "Si besoin, programmez-vous des alertes pour vous rappeler les moments où vous devez prendre une pause au cours de la journée.",
                    },
                },
                advice3: {
                    title: {
                        text1: "Se détendre",
                        text2: " en douceur",
                    },
                    sub: {
                        text1: "Le yoga, le stretching ou le pilate sont de bons moyens pour apaiser ses angoisses.",
                        text2: "Essayez dès maintenant ces petits exercices :",
                        text3: "Commencez par vous allonger sur le dos et étirez vos bras et vos jambes sur toute leur longueur en respirant doucement.",
                        text4: "Puis placez-vous en position de l'enfant : asseyez-vous sur les genoux, fesses contre talons, penchez-vous en avant de sorte que votre front touche le sol. Placez vos bras le long du corps, paumes des mains vers le ciel.",
                        text5: "Relâchez complètement tous vos muscles, puis inspirez lentement et expirez hors de votre corps tout ce que vous souhaitez évacuer. Restez ainsi quelques respirations.",
                        text6: "Enfin, faites le cobra : allongez-vous au sol sur le ventre, les mains à plat, placées sous les épaules, les jambes tendues, dessus des pieds contre le sol.",
                        text7: "Poussez sur vos mains, engagez vos abdo pour soulever doucement votre poitrine et redressez-vous sans pour autant forcer dans le dos.",
                        text8: "Maintenez la position en respirant calmement et profondément à plusieurs reprises puis reposez doucement le haut du corps sur le sol. Tournez la tête de côté et posez confortablement la joue sur vos mains. Détendez-vous ainsi pendant quelques respirations.",
                    },
                },
                advice4: {
                    title: {
                        text1: "Respirer au carré",
                        text2: "pour se détendre",
                    },
                    sub: {
                        text1: "Quand nous sommes angoissés, notre respiration s’accélère et prend un rythme inefficace qui génère une hyperventilation dans le corps. Ainsi, plutôt que de vous tourner vers la nourriture, tournez-vous vers la \"respiration au carré\" pour apaiser vos angoisses en réoxygénant votre corps. Cela consiste à pratiquer une respiration égale dans l'inspiration et l'expiration.",
                        text2: "Installez-vous confortablement en ouvrant le haut de votre corps. Inspirez par le nez pendant 4 secondes puis bloquez votre respiration 4 secondes, expirez par le nez pendant 4 secondes puis bloquez à nouveau pendant 4 secondes. Répétez ce cycle 10 à 20 fois. Vous pouvez faire durer les temps d'inspiration plus ou moins longtemps, tant qu'ils restent équivalents.",
                    },
                },
                advice5: {
                    title: {
                        text1: "Créer",
                        text2: "pour apaiser ses angoisses",
                    },
                    sub: {
                        text1: "L'une des conséquences de l'angoisse est de perdre l'envie et le plaisir.",
                        text2: "Au fur et à mesure, nous arrêtons de faire les choses qui nous faisaient du bien auparavant, on se replie et se renferme sur soi pour se protéger. Ainsi, plutôt que de vous tourner vers l'alimentation, pratiquez une activité créatrice. Bricolage, dessin, couture, peinture, écriture... le choix vous appartient.",
                        text3: "Toute activité créatrice a un effet décontractant non négligeable sur le corps et un pouvoir valorisant. Faire fonctionner votre créativité vous permettra de vous ouvrir, de vous laisser aller et de vous (re)découvrir.",
                        text4: "Alors ? Vers quelle activité allez-vous jeter votre dévolu ? :)",
                    },
                },
                advice6: {
                    title: {
                        text1: "Rire",
                        text2: "pour conjurer ses angoisses",
                    },
                    sub: {
                        text1: "L'angoisse génère souvent des tensions et un besoin de contrôle. L'alimentation peut alors intervenir comme un exutoire. Mais d'autres alternatives existent, plus efficaces, plus positives et plus saines telles que… le rire !",
                        text2: "Le rire est une véritable thérapie, il permet de se décharger de ses blocages et de réduire la perception de la douleur, de manière ludique et bienfaisante.",
                        text3: "Prenez quelques minutes pour regarder une vidéo, écouter un sketch ou appeler un.e ami.e qui vous fait rire. Vous pouvez aussi vous appliquer à rechercher cet autre rire qui est l'humour de soi. Cela revient à avouer vos faiblesses, les reconnaître et vous en amuser. Signe que vous n'en n'avez plus peur. Rire de soi, c'est rire de tout. Apprendre à rire de vous est une bonne manière de dompter vos angoisses pour les transformer en force.",
                    },
                },
            },
            motivation: {
                advice1: {
                    title: {
                        text1: "Retrouvez votre motivation grâce à",
                        text2: "votre fierté",
                    },
                    sub: {
                        text1: "Une baisse de motivation ? Ça arrive, rien de plus normal et nous sommes là pour vous aider.",
                        text2: "Perdre du poids n'est pas un long fleuve tranquille, c'est pourquoi il est important que vous vous rappeliez les raisons qui vous ont poussées à entamer votre programme. Mettez-les par écrits et notez les bénéfices santé et bien-être que vous avez déjà obtenus ! Également, projetez-vous sur l'après, quand vous aurez atteint votre poids cible : comment vous visualisez-vous ? Quels vêtements portez-vous ? Que faites-vous que vous ne faisiez pas avant ?",
                        text3: "Quoi qu'il en soit, soyez déjà fier.e de vous, du chemin que vous avez déjà parcouru. Vous allez y arriver, nous n'en doutons pas une seule seconde !",
                    },
                },
                advice2: {
                    title: {
                        text1: "Retrouvez votre motivation grâce à",
                        text2: "votre objectif",
                    },
                    sub: {
                        text1: "Vous avez perdu du poids mais votre objectif vous semble encore loin ? Pas d'inquiétude. Rome ne s'est pas construite en un jour ! Laissez vous du temps, vous allez y arriver.",
                        text2: "Pour ce faire, procédez mentalement à des objectifs intermédiaires avec des étapes différentes, de 5 kg en 5kg... Et pourquoi ne pas vous offrir un petit cadeau à chaque fois que vous passez un palier (un bijoux, un objet de décoration, un vêtement...)",
                        text3: "Soyez plus dans le ressenti du corps que focalisé.e sur le chiffre affiché par la balance. Mesurez votre tour de taille, de cuisses, de hanches, de bras et de poitrine : ce qui ne se voit pas sur la balance peut se mesurer sur la silhouette.",
                        text4: "Rappelez-vous le chemin que vous avez déjà parcouru et pensez au moment où votre objectif aura été atteint, avec le recul ça ne vous aura pas semblé si long.",
                    },
                },
                advice3: {
                    title: {
                        text1: "Retrouvez votre motivation grâce à",
                        text2: "votre bienveillance",
                    },
                    sub: {
                        text1: "La démotivation génère parfois de l'autocritique et un jugement sévère à son encontre... Hors, passer par des phases de démotivation est tout à fait légitime. Faites preuve de bienveillance envers vous-même, listez vos succès sur un papier, vos qualités, vos forces... Vous verrez qu'ils sont plus nombreux que vous le pensez, si vous acceptez de les voir.",
                        text2: "Et surtout, adressez-vous à vous-même comme vous le feriez à un.e ami.e proche. Vous vous rendrez peut-être compte que vous êtes plus intransigeant.e avec vous-même qu'avec les autres.",
                        text3: "Enfin, évitez de vous comparer aux autres. Perdre du poids n'est pas une course contre la montre et faire un écart ne constitue pas un échec, mais une occasion d'en apprendre plus sur soi-même.",
                    },
                },
                advice4: {
                    title: {
                        text1: "Retrouvez",
                        text2: "votre motivation du premier jour",
                    },
                    sub: {
                        text1: "Au fur et à mesure que vous avancez dans votre programme, vous perdez peut-être un peu votre motivation de départ et c'est normal. Conserver une motivation totale est constante n'est pas évident.",
                        text2: "C'est pourquoi il est important de vous rappeler le premier jour de votre programme. Que ressentiez-vous au début ? Que ressentez-vous maintenant ?",
                        text3: "Asseyez-vous tranquillement et listez l'ensemble des raisons qui vous ont poussé.e à démarrer votre rééquilibrage alimentaire : était-ce pour un évènement particulier (mariage, anniversaire...) ? Pour pouvoir rentrer dans votre vieux jean préféré ? Pour monter les escaliers sans être essoufflé ? Pour être en meilleure santé ? Accrochez cette liste sur votre frigo et consultez-là dès que vous avez une petite baisse de régime. Vous êtes largement capable d'y arriver !",
                    },
                },
                advice5: {
                    title: {
                        text1: "Prenez-vous en photo",
                        text2: "pour rester motivé.e",
                    },
                    sub: {
                        text1: "La difficulté au cours d'une perte de poids est de se rendre compte du chemin parcouru. Certes, le poids affiché sur la balance est un indicateur, vos mensurations également, mais le plus important est de vous rendre compte de l'évolution de votre silhouette et de vous y sentir de mieux en mieux. Hors, en vous regardant chaque jour dans le miroir, peut-être ne voyez vous pas la transformation qui s'opère sur votre corps.",
                        text2: "La solution ? Vous prendre en photo ! Une fois par semaine, une fois toutes les deux semaines ou tous les mois, c'est vous qui voyez ! En vous prenant de face et de profil, avec des vêtements qui vous permettent de voir la différence, vous retrouverez la motivation de poursuivre l'atteinte de votre objectif.",
                        text3: "Pour ce faire, téléchargez notre application mobile \"COMME J'AIME\". Vous y retrouverez des indications pour vous prendre en photo dans les meilleures conditions et vous pourrez observez votre évolution progressive. (Vos photos sont sécurisées et vous seul.e y avez accès).",
                    },
                },
            },
            fatigue: {
                advice1: {
                    title: {
                        text1: "L'",
                        text2: "alimentation équilibrée",
                        text3: "apporte l'",
                        text4: "énergie",
                        text5: "dont le corps a besoin",
                    },
                    sub: {
                        text1: "La fatigue peut trouver sa source dans différents facteurs, notamment dans l'alimentation.",
                        text2: "Une alimentation équilibrée permet à votre organisme de bien fonctionner et d'éviter les carences. C'est pourquoi, vous devez vous assurer de bien suivre votre programme COMME J'AIME en y ajoutant des légumes et des fruits pour faire le plein d'énergie et de minéraux. Veillez également à prendre votre collation l'après-midi, elle permet de pallier la baisse d'énergie qui peut se manifester en fin de journée.",
                        text3: "Vous suivez déjà votre programme à la lettre ? Tournez vos observations vers la qualité et la quantité de votre sommeil...",
                    },
                },
                advice2: {
                    title: {
                        text1: "Quand la",
                        text2: "fatigue",
                        text3: "est liée au",
                        text4: "manque de sommeil",
                    },
                    sub: {
                        text1: "La fatigue liée au manque de sommeil ou à un sommeil de mauvaise qualité perturbe le système hormonal, générant ainsi une baisse d'énergie qui pousse le corps à compenser par des grignotages, avec des aliments gras, sucrés ou salés et à consommer des excitants. C'est de cette manière qu'un cercle vicieux s’installe.",
                        text2: "Dans l'immédiat, si l'envie de grignoter est trop forte, essayez de vous tourner vers un yaourt, un fruit ou une tranche de jambon.",
                        text3: "De manière plus générale, observez votre sommeil :",
                        text4: "Dormez-vous au moins 7h par nuit ?",
                        text5: "Si ce n'est pas le cas, essayez d'instaurer un rituel pour vous coucher et vous réveillez à heures fixes. Veillez à vous endormir avant minuit pour une nuit plus réparatrice.",
                        text6: "Votre sommeil est-il de bonne qualité ?",
                        text7: "Plus difficile à analyser, mais pas moins important. Coupez vos écrans 2h avant de vous coucher, pratiquez une activité physique au cours de la journée, mangez de manière équilibrée afin de garantir un sommeil de qualité.",
                    },
                },
                advice3: {
                    title: {
                        text1: "Augmenter sa dépense énergétique",
                        text2: "pour être en forme",
                    },
                    sub: {
                        text1: "La fatigue entraîne une baisse de motivation à pratiquer une activité physique et le manque d'activité physique renforce la sensation de fatigue. Encore un cercle vicieux dont il est difficile de sortir !",
                        text2: "Pourtant, pratiquer une activité physique constitue une solution pour lutter contre la fatigue. L'exercice aide votre organisme à réguler son horloge interne grâce aux endorphines et à la sérotonine (hormones du bien être) qui sont libérées. Ainsi, l'effort procure une saine fatigue, aide à mieux dormir et fournit à l'organisme plus de tonus.",
                        text3: "Bien sûr, on ne parle pas ici de courir un marathon. Commencez simplement par marcher 30 minutes par jour et augmentez l'intensité et la durée peu à peu. Pensez également à vous hydrater tout au long de la journée avec l'eau, les tisanes, les produits laitiers non sucré (yaourt, fromage blanc) et les légumes.",
                    },
                },
                advice4: {
                    title: {
                        text1: "Bien",
                        text2: "dîner",
                        text3: "pour bien",
                        text4: "dormir",
                    },
                    sub: {
                        text1: "La fatigue peut être liée à un sommeil de mauvaise qualité.",
                        text2: "Vous connaissez certainement l'expression \"qui dort dîne\" ? À l'époque du Moyen Âge, elle informait les voyageurs qui voulaient dormir dans une auberge qu'ils étaient contraints d'y dîner, sous peine de se voir refuser le gîte. Aujourd'hui, cette expression traduit simplement que l'un ne va pas sans l'autre : si l'on veut dormir, il faut manger avant. Et à juste titre !",
                        text3: "Pour bien dormir, il est nécessaire de bien dîner. Pour ce faire, quelques règles doivent être observées :",
                        text4: "Évitez les repas copieux qui alourdiront la digestion. Pour ce faire, veillez à bien respecter votre menu COMME J'AIME et surtout, soyez attentif.ve au signal envoyé par votre corps quand il est rassasié.",
                        text5: "Programmez l'heure de votre dîner en fonction de l'heure de votre coucher. L'idéal est de finir de manger environ 2h avant d'aller vous coucher, pour laisser le temps à votre corps de digérer et de baisser sa température.",
                        text6: "Ne buvez pas d'alcool. Bien que l'on pense qu'il favorise l'endormissement, il réduit surtout la qualité du sommeil.",
                        text7: "Optez pour une bonne tisane qui favorisera votre détente et votre endormissement.",
                    },
                },
                advice5: {
                    title: {
                        text1: "Créer un",
                        text2: "rituel",
                        text3: "propice à l'",
                        text4: "endormissement",
                    },
                    sub: {
                        text1: "Bien dormir est la base pour être en pleine forme dans la journée",
                        text2: "Au même titre que les \"morning rituals\" aident à bien démarrer la journée, les rituels du soir sont tout aussi importants pour préparer le corps au sommeil. Ils sont propres à chacun selon le rythme de vie et le fonctionnement du corps mais voici un exemple dont vous pouvez vous inspirer pour trouver votre propre rituel :",
                        text3: "19h :",
                        text4: "Faire une séance de méditation pour vous déconnecter des tracas de votre journée",
                        text5: "19h30 :",
                        text6: "Prendre une douche pour vous délasser puis enfiler une tenue confortable",
                        text7: "20h :",
                        text8: "Dîner (sans écran, assis.e et au calme)",
                        text9: "20h30 :",
                        text10: "Prendre une tisane pour vous relaxer en vous adonnant à un passe-temps de votre choix (évitez les écrans)",
                        text11: "22h30 :",
                        text12: "Lire un bouquin au lit",
                        text13: "23h :",
                        text14: "Éteindre la lumière pour s'endormir",
                        text15: "Notez que si vous souhaitez pratiquer une activité physique le soir, il est préférable de le faire 2 à 3h avant de dormir afin d'éviter une augmentation du rythme cardiaque et de la pression artérielle qui auraient tendance à mettre le cerveau en éveil.",
                    },
                },
                advice6: {
                    title: {
                        text1: "Combattre",
                        text2: "le surmenage",
                        text3: "pour lutter contre la fatigue",
                    },
                    sub: {
                        text1: "Que ça soit au travail ou à la maison, le surmenage peut être la cause d'une grande fatigue.",
                        text2: "Si c'est votre cas, autorisez-vous plusieurs pauses de 15 minutes dans la journée. N'hésitez pas à vous programmer une alerte si besoin pour vous arrêter : allez marcher dehors, étirez-vous, prenez de grandes inspirations et expirations, buvez une tisane...",
                        text3: "Bref, accordez-vous des moments de détente.",
                        text4: "Dans l'immédiat, avant de craquer sur un aliment, essayez de vous détendre et d'aérer votre esprit. Si après ça vous ressentez toujours le besoin de manger, réfléchissez à un aliment sain que vous pourriez prendre afin de transformer un potentiel grignotage en collation encadrée.",
                    },
                },
                advice7: {
                    title: {
                        text1: "Des",
                        text2: "compléments alimentaires",
                        text3: "pour retrouver la forme",
                    },
                    sub: {
                        text1: "Vous ressentez de la fatigue depuis plusieurs jours, ou semaines ? Une cure de compléments alimentaires pourrait bien être le petit coup de boost dont vous avez besoin pour retrouver le chemin de la forme !",
                        text2: "Les compléments alimentaires sont des denrées alimentaires présentées sous forme de gélules, de comprimés, d’ampoules ou de capsules. Ils sont des sources concentrées de nutriments.",
                        text3: "Ils sont préconisés dans les cas suivants :",
                        text4: "si vous avez accumulés des carences dans votre alimentation avant de démarrer le programme COMME J'AIME ou si vous ne suivez pas correctement le programme,",
                        text5: "si vous vivez dans un environnement pollué, exposé aux pesticides ou que vous êtes régulièrement surexposé au soleil,",
                        text6: "si vous êtes de nature stressée",
                        text7: "N'hésitez pas à en parler à votre coach lors de votre prochaine séance pour obtenir plus d'informations sur les compléments alimentaires.",
                    },
                },
                advice8: {
                    title: {
                        text1: "Lutter contre la fatigue en s'",
                        text2: "hydratant",
                    },
                    sub: {
                        text1: "Parfois, il n'est pas nécessaire de chercher trop loin les origines de la fatigue : elle est parfois signe d'un corps déshydraté.",
                        text2: "En effet, en cas de déshydratation modérée, l'organisme a plus de difficultés à effectuer les tâches d'autant que le cerveau est très sensible aux pertes d'eau. L'organisme fonctionne alors au ralenti et avec difficulté, entraînant un état somnolent, des étourdissements et une fatigue intense.",
                        text3: "Dans l'immédiat, commencez donc par boire un grand verre d'eau.",
                        text4: "Et de manière plus générale, assurez-vous de boire 1,5L d'eau par jour. Limitez le café et le thé à 3 tasses par jour, pas après 16h. Quant à l'alcool, sachez qu'il aura tendance à accroître votre état de fatigue... À consommer avec une grande modération, donc.",
                    },
                },
            },
            frustration: {
                advice1: {
                    title: {
                        text1: "Quand la",
                        text2: "frustration",
                        text3: "génère le",
                        text4: "besoin de manger",
                    },
                    sub: {
                        text1: "Le sentiment de frustration est une réponse émotionnelle à une résistance, qui va à l'encontre de votre volonté ou de vos désirs. Plus la frustration est grande, plus vos pensées deviennent obsessives envers les aliments. Et une fois la règle transgressée, la dimension du craquage n’a plus d’importance.",
                        text2: "C'est pourquoi il est essentiel que vous suiviez bien votre programme COMME J'AIME, en y ajoutant les légumes, les fruits et les produits laitiers conseillés pour garantir une grande variété dans votre assiette. Également, n'hésitez pas à consulter votre guide de bienvenue dans lequel nous vous proposons des équivalences pour varier vos plaisirs.",
                        text3: "Rappelez-vous également qu'il n'existe pas d'aliments diaboliques. Accordez-vous des plaisirs de temps en temps. Et si vous avez peur de les manger en trop grosse quantité, divisez par deux la quantité que vous aviez imaginé au départ, asseyez-vous dans un endroit calme et dégustez votre aliment plaisir petit bout par petit bout. Prenez le temps de le savourer et d'en faire un moment privilégié qui vous satisfera.",
                    },
                },
                advice2: {
                    title: {
                        text1: "Avant, pendant",
                        text2: "et",
                        text3: "après",
                        text4: "la frustration",
                    },
                    sub: {
                        text1: "Le meilleur moyen pour éviter de craquer est d'éviter... la frustration ! Intégrez ponctuellement dans vos menus, en quantité raisonnable, les aliments qui vous font du bien. Pourquoi ? Non seulement parce qu'il est impensable de passer une vie entière sans prendre de petits plaisirs, mais en plus parce que la privation totale d'un aliment engendre un dérapage démesuré envers celui-ci à un moment ou un autre.",
                        text2: "Si le sentiment de frustration se présente tout de même à vous, demandez-vous ce qui la provoqué et l'émotion qu'il traduit. Du stress ? De la colère ? La tristesse ? Essayez de comprendre quel besoin vous souhaitez combler en craquant pour votre aliment plaisir.",
                        text3: "Et si vous craquez, ne vous culpabilisez pas et surtout, ne réduisez pas à néant tous les efforts que vous avez accomplis jusque là. Dites-vous que ça arrive à tout le monde et n'accordez pas à votre écart plus d'importance qu'il n'en n'a. Vous n'aurez qu'à adapter votre repas suivant et à continuer sereinement sur votre lancée.",
                    },
                },
                advice3: {
                    title: {
                        text1: "Variez les plaisirs",
                        text2: "en cuisine pour éloigner la frustration",
                    },
                    sub: {
                        text1: "La frustration peut parfois prendre naissance dans de petits détails, facilement ajustables. Par exemple, le choix et la cuisson des légumes.",
                        text2: "Si vous accompagnez toujours vos plats COMME J'AIME des mêmes légumes, cuits de la même manière, il est tout à fait légitime que vous ressentiez de la frustration. Tentez différentes cuissons : à la vapeur, au four, en gratin ou même crus.",
                        text3: "Variez également les assaisonnement à l'aide d'herbes aromatiques, d'épices ou de vinaigre.",
                        text4: "Faites appel à votre créativité et à votre curiosité pour tester de nouvelles recettes.",
                    },
                },
                advice4: {
                    title: {
                        text1: "Frustration et",
                        text2: "satiété",
                    },
                    sub: {
                        text1: "La frustration peut entraîner des pensées obsessives envers l'aliment désiré et la quantité que l'on est pas capable d'en consommer. Ainsi, la sensation de satiété est mise à mal. Dans ces moments là, optez pour une séance de dégustation avec votre aliment plaisir : prenez une petite quantité, gardez-le en bouche, mâchez-le en fermant les yeux et décrivez-en l'odeur, la texture et la saveur. Ainsi, vous retrouvez le réel plaisir qu'il incarne, vous saurez limiter la quantité et surtout, vous ne culpabiliserez pas de vous l'être accordé.",
                    },
                },
                advice5: {
                    title: {
                        text1: "Quand",
                        text2: "le yoga apaise",
                        text3: "les frustrations",
                    },
                    sub: {
                        text1: "Derrière le sentiment de frustration se cache le ressassement, l'obsession pour l'aliment du désir, la contrainte, l'insatisfaction…",
                        text2: "Pour prendre du recul sur ces émotions et vous éloigner de cette sensation de frustration, essayez le yoga.",
                        text3: "Ses multiples bénéfices ne sont plus à prouver, parmi eux : le lâcher prise.",
                        text4: "En pratiquant du yoga, vous apprendrez à accepter ce que vous ne pouvez contrôler, à accueillir vos émotions en toute bienveillance, à écouter votre corps physique et à retrouver le calme intérieur.",
                        text5: "Que diriez-vous de repousser à plus tard la prise de votre aliment plaisir au profit d'une petite séance de yoga ?",
                    },
                },
            },
            happy: {
                advice1: {
                    title: {
                        text1: "Se faire plaisir",
                        text2: "avec modération",
                    },
                    sub: {
                        text1: "Vous êtes heureux.se, vous vous sentez bien et vous souhaitez vous accorder un petit plaisir alimentaire ? Vous avez raison! Il est important de savoir s'octroyer des petits plaisirs. Il faut cependant faire attention à ne pas tomber dans l'excès pour ne pas gâcher tous les efforts que vous avez fournis. Pensez à ce que vous aimez le plus (chocolat, confiseries, pizza, frites, fromage...), choisissez un plaisir et fixez-vous à l'avance une quantité raisonnable à consommer. Votre esprit sera conditionné psychologiquement, vous ne serez pas tenté.e de vous resservir et vous apprécierez d'autant plus votre repas.",
                        text2: "Vous serez fier.e de vous, d'avoir su profiter de cet écart et pourrez poursuivre votre perte de poids sans frustration.",
                    },
                },
                advice2: {
                    title: {
                        text1: "Déguster",
                        text2: "son plaisir",
                    },
                    sub: {
                        text1: "Quel agréable sensation que de se sentir heureux.se ! Peut-être que ce sentiment vous conduit-il d'ailleurs à vouloir vous accorder un petit plaisir alimentaire ? Aucun soucis... tant que c'est exceptionnel, pris en petite quantité et que vous le dégustez.",
                        text2: "Choisissez l'aliment qui vous fait envie, prenez-le en quantité raisonnable, gardez-le en bouche, mâchez-le en fermant les yeux et décrivez tous les goûts qu'il révèle en bouche, sa texture et sa saveur. Finissez toujours votre bouchée avant d'en prendre une nouvelle. Profitez de ce moment et appréciez ... sans vous précipiter.",
                    },
                },
                advice3: {
                    title: {
                        text1: "Exploiter ses",
                        text2: "bonnes énergies",
                    },
                    sub: {
                        text1: "Se sentir heureux.se peut conduire à vouloir s'accorder des plaisirs culinaires. Et ce n'est pas un mal, mais dans la mesure où vous avez de l'énergie à revendre, pourquoi ne pas la porter sur autre chose que la nourriture ?",
                        text2: "Par exemple, sortez marcher, allez voir des amis, pratiquez une activité physique, ou des travaux manuels...",
                        text3: "Profitez de cette période de tranquillité émotionnelle pour vous ouvrir à de nouvelles activités ou pour créer de nouveaux projets.",
                        text4: "De cette manière, vous focaliserez votre énergie positive sur autre chose que la nourriture qui risquerait de vous faire prendre du poids et de vous faire culpabiliser. Changez ce cercle vicieux en cercle vertueux.",
                    },
                },
                advice4: {
                    title: {
                        text1: "Exprimer",
                        text2: "son bonheur",
                    },
                    sub: {
                        text1: "Vous êtes heureux.se, quel sentiment exaltant ! Peut-être qu'en voulant vous accorder un petit plaisir alimentaire, vous souhaitez simplement extérioriser votre bonheur ? Ainsi, plutôt que de vous diriger vers l'aliments de votre désir, appelez plutôt un.e ami.e proche pour lui faire part de vos sentiments positifs.",
                        text2: "Ou exprimez-les par écrit ou chantez-les, dansez-les, criez-les sur tous les toits ! En d'autres termes : extériorisez-les. Le bonheur est contagieux alors faites en profiter votre entourage...",
                    },
                },
                advice5: {
                    title: {
                        text1: "Cadrer",
                        text2: "ses plaisirs",
                    },
                    sub: {
                        text1: "Être heureux.s conduit parfois à vouloir se lâcher. C'est d'ailleurs pourquoi il est de coutume de s'offrir un bon repas pour célébrer une bonne nouvelle ou un heureux évènement !",
                        text2: "Pour vous assurer de ne pas transformer votre plaisir en culpabilité, veillez à ne pas manger en excès. Vous pouvez vous accorder des aliments un peu gras, salés ou sucrées, mais en petite quantité et de manière ponctuelle. Privilégiez toujours les besoins réels de votre corps : légumes, protéines, féculents avant de craquer pour des aliments moins essentiels.",
                        text3: "Enfin, soyez attentif.ve au sensation de satiété pour ne pas manger plus que de raison et buvez beaucoup d'eau.",
                    },
                },
                advice6: {
                    title: {
                        text1: "Identifier",
                        text2: "ses besoins",
                    },
                    sub: {
                        text1: "Vous êtes hereux.se et peut-être avez-vous envie de vous accorder des plaisirs alimentaires ?",
                        text2: "C'est le meilleur moment de tenter de comprendre votre fonctionnement et d'analyser à quel besoin correspond votre besoin de manger.",
                        text3: "Traduit-elle l'envie de partager de bons moments avec des gens que vous aimez ? Si c'est le cas, appelez-les et proposez-leurs une promenade ou une activité de groupe.",
                        text4: "Traduit-elle un réflexe du passé, lorsque vos parents vous gratifiaient d'une sucrerie pour vous récompenser d'une bonne action ? Repérer ce schéma inconscient vous permettra de remplacer la nourriture par une autre récompense.",
                        text5: "Traduit-elle le besoin de décompresser ? Trouvez les activités qui vous font plaisir et qui vous apporteront cette sensation de décompression.",
                        text6: "En mobilisant votre énergie positive pour comprendre les mécanismes qui vous poussent à manger, même dans les moments de bonheur, vous serez plus en clin à modifier un cercle vicieux en un cercle vertueux.",
                    },
                },
            },
            overflow: {
                advice1: {
                    title: {
                        text1: "S'organiser",
                        text2: "de manière efficace",
                    },
                    sub: {
                        text1: "Vous vous sentez débordé.e et l'alimentation apparaît comme la solution pour reprendre des forces et vous donner du courage ? Recentrez-vous. Asseyez vous quelques instants et faites une liste de l'ensemble des tâches que vous devez effectuer puis priorisez-les selon la matrice d'Eisenhower :",
                        text2: "Les tâches importantes et urgentes : faites-les tout de suite (ex : de l'administratif, répondre à une annonce immobilière, appeler un dépanneur...)",
                        text3: "Les tâches importantes mais pas urgentes : planifiez-les pour les effectuer dans les prochains jours (faire de la méditation ou du sport, passer des moments en famille, développer des compétences dans un domaine...)",
                        text4: "Les tâches pas importantes et non urgentes : remettez-les à plus tard (aller sur les réseaux sociaux, faire du shopping, regarder une série...)",
                        text5: "Les tâches urgentes mais non importantes : faites-les faire par quelqu'un d'autre (répondre aux SMS / téléphone, rendre un service qu'on nous demande...)",
                        text6: "Cet exercice vous permettra de libérer votre cerveau de ce que vous avez à faire, d'être plus efficace dans l'accomplissement de vos tâches.",
                    },
                },
                advice2: {
                    title: {
                        text1: "Faire une pause",
                        text2: "s'impose",
                    },
                    sub: {
                        text1: "Quand on se sent débordé.e, on a tendance à se tourner vers l'alimentation.",
                        text2: "Hors, peut-être que la seule chose dont vous avez besoin, c'est de vous accorder une pause, même brève (10-15 min). Prenez le temps de vous relaxer. Faites le vide et travaillez sur votre respiration en inspirant et en expirant lentement.",
                        text3: "Vous pouvez également écouter de la musique, ou vaquer à une occupation qui vous plaît et qui vous permet de relâcher la pression. Une fois détendu.e, le besoin de manger s'estompera.",
                        text4: "Au besoin, programmez-vous des alertes pour vous rappeler les moments où vous devez prendre une pause au cours de la journée.",
                    },
                },
                advice3: {
                    title: {
                        text1: "Déléguer",
                        text2: "pour avancer",
                    },
                    sub: {
                        text1: "Quand vous vous sentez débordé.e, demandez-vous si vous sollicitez suffisamment votre entourage pour vous aider, que ça soit vos collègues dans le travail, votre famille dans les tâches quotidiennes, ou vos amis. Il est normal que certaines périodes soient plus chargées que d'autres, et il est d'autant plus normal de demander de l'aide pour se soulager de certaines tâches. Alors ? Vers qui allez-vous vous tourner ? :)",
                    },
                },
                advice4: {
                    title: {
                        text1: "Pratiquer une",
                        text2: "activité physique",
                        text3: "pour sortir la tête de l'eau",
                    },
                    sub: {
                        text1: "Vous avez une tonne de choses à faire et vous vous dites que vous n’aurez jamais suffisamment le temps de tout accomplir. Face à cette masse de tâches, vous ne savez pas par où commencer et tout se mélange dans votre tête. C'est pourquoi vous avez besoin de reprendre des forces ! Si se tourner vers la nourriture est une fausse bonne idée, pratiquer une activité physique ne peut qu'être salvateur.",
                        text2: "Cela permet à votre organisme de sécréter de la sérotonine et des endorphines (les hormones du bien être) et de vous redonner le coup de boost dont vous avez besoin.",
                        text4: "Faites ce qui vous plaît : de la marche, du stretching, de la course à pieds, de la musculation, du yoga, ... 10 mn suffisent pour vous permettre de vous défouler, de détendre vos muscles et de repartir du bon pied.",
                    },
                },
                advice5: {
                    title: {
                        text1: "Savoir dire",
                        text2: "NON",
                    },
                    sub: {
                        text1: "Il n'est pas toujours facile de dire \"Non\" à une tâche ou à un service que l'on vous demande d'accomplir. Et pour cause ! Dire \"oui\" à tout est un réflexe très commun que beaucoup de personnes ont quand elles veulent aider les autres et bien faire les choses.",
                        text2: "Si vous vous sentez débordé.e, c'est peut-être que vous ne dites pas assez \"non\". Pour apprendre à le faire sans culpabiliser, voici quelques pistes que vous pouvez explorer :",
                        text3: "Estimez le temps que vous avez. À chaque fois que vous dites \"oui\" à une tâche, c'est du temps que vous y allouez... Encore faut-il que vous en ayez.",
                        text4: "Apprenez à connaître vos priorités pour aligner les demandes que l'on vous adresse sur celles-ci. Une demande est alignée sur votre priorité ? Acceptez-la. Elle ne l'est pas ? Refusez-la.",
                        text5: "Communiquez la raison pour laquelle vous refusez une tâche afin de mieux faire accepter votre refus.",
                        text6: "Proposez une alternative. En disant \"non\", rien ne vous empêche d'indiquer une personne susceptible de pouvoir le faire à votre place, ou une ressource pour aider votre interlocuteur à le faire lui-même.",
                    },
                },
                advice6: {
                    title: {
                        text1: "Désactiver les notifications",
                        text2: "pour se sentir moins débordé.e",
                    },
                    sub: {
                        text1: "Les notifications que l’on reçoit sur nos smartphones ou ordinateurs contribuent largement au sentiment de débordement. A chaque fois qu’elles apparaissent, on se sent étrangement obligé de les traiter dans l'immédiat, ce qui encombre notre charge mentale.",
                        text2: "C'est pourquoi, plutôt que de vous accorder un petit craquage alimentaire, commencez par désactiver toutes les notifications inutiles (réseaux sociaux, actualité, application de jeux, etc...) pour ne garder que l'essentiel. Pour ce qui est de vos mails, choisissez les heures auxquelles vous souhaitez les consulter afin d'atténuer la pression engendrée par l'instantanéité.",
                    },
                },
            },
            sade: {
                advice1: {
                    title: {
                        text1: "Anticiper",
                        text2: "votre ressenti",
                    },
                    sub: {
                        text1: "Votre tristesse vous appartient et il est douloureux de l'affronter, c'est pourquoi la nourriture incarne alors un rôle réconfortant. Mais quand cela est systématique, cette stratégie d'évitement devient pesante ! Apprenez à anticiper cet état en faisant une activité qui vous rappelle des souvenirs heureux, des moments d'émerveillements, des rires partagés ... afin que ce moment soit moins difficile à appréhender en le laissant venir et ... partir.",
                        text2: "Et dans l'immédiat, prenez 10-15mn pour vaquer à une occupation récréative qui vous fait du bien : marcher, lire un bouquin, écouter de la musique, dessiner...",
                    },
                },
                advice2: {
                    title: {
                        text1: "Accueillir",
                        text2: "et",
                        text3: "comprendre",
                        text4: "sa tristesse",
                    },
                    sub: {
                        text1: "La première chose à faire lorsque l'on se sent triste est d'accepter cette émotion et de l'accueillir avec bienveillance. Vous ressentez le besoin de pleurer ? Ne vous gênez pas ! Exprimer ses émotions est important et vous permettra de vous alléger d'un poids, davantage qu'en compensant votre sentiment par l'alimentation.",
                        text2: "Comprendre votre émotion est tout aussi important pour vous en détacher petit à petit. Prenez un papier et un stylo puis notez ce que vous ressentez en détail et les évènements à l'origine de cette tristesse.",
                        text3: "Au besoin, tournez vers un.e personne de confiance, qui vous connaît et qui a le don d'écoute telle qu'un.e ami.e ou un parent proche pour exprimer ce que vous ressentez.",
                        text4: "Parler à un professionnel tel qu'un médecin, un thérapeute ou un conseiller peut également vous aider à analyser et à apaiser votre tristesse.",
                    },
                },
                advice3: {
                    title: {
                        text1: "Sortir",
                        text2: "de chez soi",
                    },
                    sub: {
                        text1: "La tristesse peut vous conduire dans le cercle vicieux de l'isolation qui entretient le sentiment de tristesse. Hors, prendre un bol d'air frais et entretenir des relations sociales est justement un moyen d'apaiser la tristesse. Prenez dès maintenant le temps de vous accorder une bonne promenade en plein air et / ou de voir un.e ami.e.",
                        text2: "Marcher dans un environnement agréable fera vagabonder vos pensées et vous éloignera de l'envie de grignoter. Observez tout ce qui entoure, vous enveloppe et vous émerveille.",
                        text3: "Voir un.e ami.e vous permettra de partager vos ressentis, ou au contraire, de vous en éloigner en parlant d'autre chose, en riant, en partageant un moment agréable avec une personne que vous appréciez.",
                    },
                },
                advice4: {
                    title: {
                        text1: "Faire preuve de",
                        text2: "bienveillance",
                        text3: "avec soi-même",
                    },
                    sub: {
                        text1: "La tristesse génère parfois de l'autocritique et un jugement sévère à son encontre... Aidez-vous en étant bienveillant.e avec vous-même et prenez soin de vous comme vous le feriez avec un ami.e ou un membre de votre famille. Veillez à ne pas vous infliger de pression supplémentaire en vous martelant \"ça va passer\". Certes, la tristesse disparaît avec le temps et l'acceptation mais cela ne se fait pas non plus en un claquement de doigt... ni en mangeant une tablette de chocolat.",
                        text2: "Munissez-vous d'un papier et d'un stylo et notez toutes les choses positives de votre vie, vos succès, vos plaisirs quotidiens... Voir le verre à moitié plein peut vous aider à apaiser votre sentiment.",
                    },
                },
                advice5: {
                    title: {
                        text1: "Évacuer la tristesse par",
                        text2: "l'activité physique",
                    },
                    sub: {
                        text1: "La tristesse peut nous conduire à nous réfugier dans l'alimentation pour tenter d'apaiser nos émotions. Hors cette \"anesthésie ponctuelle\", bien qu'elle puisse vous soulager brièvement, aura tendance à développer ou accroître un mal-être.",
                        text2: "C'est pourquoi, pour l'évacuer sainement, rien de mieux que de pratiquer une activité physique. Cela permet à votre organisme de sécréter de la sérotonine et des endorphines (les hormones du bien être).",
                        text3: "Faites ce qui vous plaît : de la marche, du stretching, de la course à pieds, de la musculation, du yoga, ... 10 mn suffisent pour vous permettre de vous défouler, de détendre vos muscles et de réguler votre rythme cardiaque.",
                    },
                },
                advice6: {
                    title: {
                        text1: "Puiser ses",
                        text2: "ressources",
                        text3: "en soi",
                    },
                    sub: {
                        text1: "Socrate disait : \"Connais-toi toi-même\". Cette assertion signifie que c'est en se connaissant et en cherchant en lui-même que l'Homme peut trouver sa sagesse.",
                        text2: "Puisez donc en vous-même vos ressources. Demandez-vous ce qui est important pour vous, quels sont vos objectifs proches ou lointains, quels sont vos projets ? Quelles sont les passions, les centres d'intérêt qui vous définissent : l'art, le travail, l'écriture, la famille, le partage,... ? Qu'est-ce qui vous rend heureux.se ?",
                        text3: "Concentrez-vous sur les réponses à ces questions pour avancer. Et soyez certain.e d'une chose : aujourd'hui c'est peut-être difficile à croire, mais la tristesse que vous ressentez sera bénéfique sur le long terme. Elle vous aura permis de mieux vous connaître, de comprendre votre fonctionnement et vos attaches. Peut-être vous permettra-t-elle également de vous rapprocher de vos proches...",
                    },
                },
            },
            stress: {
                advice1: {
                    title: {
                        text1: "Evacuer le stress par",
                        text2: "la respiration profonde",
                    },
                    sub: {
                        text1: "Vous sentez le stress vous envahir ? Accueillez-le avec bienveillance sans chercher à vous apaiser avec la nourriture.",
                        text2: "À la place, installez vous confortablement sur une chaise ou dans un fauteuil et expérimentez la cohérence cardiaque. Cela consiste à effectuer 6 respirations par minute, soit 5 secondes d'inspiration et 5 secondes à d'expiration.",
                        text3: "Réalisez cette cohérence cardiaque pendant 5 minutes sans faire de pause, vous devriez en apprécier les bénéfices instantanément. Et pour en ressentir les bienfaits sur le long terme, renouvelez cette pratique 2 à 3 fois par jour.",
                    },
                },
                advice2: {
                    title: {
                        text1: "Remplacer le stress par",
                        text2: "une autre émotion",
                    },
                    sub: {
                        text1: "L'une des solutions pour évacuer le stress sans culpabiliser, c'est de remplacer cette émotion par une autre.",
                        text2: "Comment ? En prenant du temps pour vous. Faites-quelque chose qui vous fait plaisir : marcher, prendre un bain, écouter une musique agréable, dessiner, lire, regarder un film... Toutes les solutions sont bonnes tant qu'elles sont saines. Le but étant de détourner votre attention de votre stress pour repartir du bon pied.",
                        text3: "Alors ? Vers quelle activité allez-vous vous tourner ?",
                    },
                },
                advice3: {
                    title: {
                        text1: "Evacuer le stress par",
                        text2: "la visualisation",
                    },
                    sub: {
                        text1: "Que diriez-vous de voyager un petit peu pour évacuer votre stress ?",
                        text2: "Installez vous confortablement sur une chaise ou dans un fauteuil. Fermez les yeux et imaginez un endroit qui vous procure du bien-être, où vous vous sentez apaisé.e cela peut être une plage, une forêt ou une pièce de la maison de votre enfance. Détaillez l'environnement, le temps, les objets ou la nature qui vous entoure. Projetez vous comme si vous y étiez.",
                        text3: "Vous pouvez aussi réveiller d'agréables ou d'amusant souvenirs et remémorez-les en détail. Où était-ce ? Quand ? Avec qui ? Appelez cette personne pour partager ce bon souvenir !",
                    },
                },
                advice4: {
                    title: {
                        text1: "Évacuer le stress par",
                        text2: "l'activité physique",
                    },
                    sub: {
                        text1: "Le stress peut trouver son origine dans différente causes, il peut-être mineur ou important, positif comme négatif, constant ou ponctuel, prévu ou inattendu. Dans le cas où il devient intense et qu'il pousse à des actions qui vont à l'encontre de nos valeurs (tel que le grignotage), il faut l'évacuer !",
                        text2: "Et pour l'évacuer sainement, rien de mieux que de pratiquer une activité physique. Cela permet à votre organisme de sécréter de la sérotonine et des endorphines (les hormones du bien être).",
                        text3: "Faites ce qui vous plaît : de la marche, du stretching, de la course à pieds, de la musculation, du yoga, ... 10 mn suffisent pour vous permettre de vous défouler, de détendre vos muscles et de réguler votre rythme cardiaque.",
                    },
                },
                advice5: {
                    title: {
                        text1: "Les aliments à privilégier",
                        text2: "en cas de stress.",
                    },
                    sub: {
                        text1: "Se débarrasser du stress n'est pas une mince affaire. La bonne nouvelle, c'est que votre alimentation peut vous y aider... À condition de choisir les bons aliments !",
                        text2: "Privilégiez :",
                        text3: "Les glucides que vous trouverez dans les légumes secs, le quinoa ou les châtaignes;",
                        text4: "Le tryptophane que vous trouverez dans les légumes verts, le soja ou la banane ;",
                        text5: "Les produits laitiers riches en lactosérum tels que la ricotta ou la brousse;",
                        text6: "Le magnésium présent dans les noix, les amandes, les noisettes, la banane, les légumes secs et les eaux minérales (Rozana, Hépar, Quézac).",
                        text7: "Les Oméga-3 grâce aux poissons gras (saumon, sardines..), l'huile de colza, les amandes, les noix et les laitages de la filière bleu-blanc-cœur.",
                    },
                },
                advice6: {
                    title: {
                        text1: "Des",
                        text2: "compléments alimentaires",
                        text3: "pour déstresser",
                    },
                    sub: {
                        text1: "Vous vous sentez stressé.e depuis quelques temps ? Une cure de compléments alimentaires pourrait bien être le petit coup de boost dont vous avez besoin pour retrouver le chemin de la détente.",
                        text2: "Les compléments alimentaires sont des denrées alimentaires présentées sous forme de gélules, de comprimés, d’ampoules ou de capsules. Ils sont des sources concentrées de nutriments.",
                        text3: "Ils sont préconisés dans les cas suivants :",
                        text4: "si vous avez accumulés des carences dans votre alimentation avant de démarrer le programme COMME J'AIME ou si vous ne suivez pas correctement le programme,",
                        text5: "si vous vivez dans un environnement pollué, exposé aux pesticides ou que vous êtes régulièrement surexposé au soleil,",
                        text6: "si vous êtes de nature stressée",
                        text7: "N'hésitez pas à en parler à votre coach lors de votre prochaine séance pour obtenir plus d'informations sur les compléments alimentaires.",
                    },
                },
                advice7: {
                    title: {
                        text1: "Le pouvoir",
                        text2: "des câlins",
                        text3: "sur le stress",
                    },
                    sub: {
                        text1: "On n'en parle pas assez mais les câlins regorgent de multiples bienfaits sur notre santé, dont le pouvoir de nous déstresser. Non, ce n'est pas niais, c'est scientifique ! :)",
                        text2: "En effet, ils permettent de réduire la pression artérielle grâce à l'ocytocine qu'ils libèrent dans le sang. Ils ont également le pouvoir de renforcer le système immunitaire et par dessus-tout, ils nous rendent heureux grâce aux hormones du bien-être (endorphine, dopamine et ocytocine) qu'ils libèrent.",
                        text3: "Alors, avant de vous tourner vers l'aliment de votre désir, courrez vers votre ami.e, votre conjoint.e, votre famille ou même votre animal de compagnie le plus proche pour leur faire un câlin anti-stress !",
                    },
                },
                advice8: {
                    title: {
                        text1: "Jardiner,",
                        text2: "une thérapie anti-stress efficace",
                    },
                    sub: {
                        text1: "Depuis bien longtemps, le jardinage est reconnu pour ses vertus relaxantes.",
                        text2: "Les scientifiques parlent même de jardinothérapie ou d'­hortithérapie, qui agirait comme une méditation sur le corps. Que vous ayez ou non un jardin ou un balcon, laissez-vous tenter par le sentiment de satisfaction que procure la communion avec la nature. Nul besoin d'être un expert en jardinage pour acheter une ou plusieurs plantes et pour vous en occuper, les faire fleurir, les couper, les arroser et les contempler.",
                        text3: "Alors plutôt que de craquer pour l'aliment de votre désir, cultivez votre propre jardin...",
                    },
                },
            },
        },
        answer4: {
            after: {
                advice1: {
                    title: {
                        text1: "Se concentrer",
                        text2: "sur le repas",
                    },
                    sub: {
                        text1: "Les repas sont des moments importants qui rythment votre journée. C'est pourquoi, il est nécessaire d'y prêter toute votre attention et de vous accorder de vraies pauses. Ainsi, veillez à manger dans un endroit au calme, sans écrans (qui ont tendance à perturber les sensations de satiété). Pour vous sentir rassasié.e, mangez assis.e à table, en prenant au moins 20 minutes. Buvez un verre d'eau puis commencez par une assiette de crudités, ce qui vous obligera à bien mâcher et à manger plus lentement.!",
                        text2: "Vous devriez vous sentir mieux rassasié.e à la fin de votre repas.",
                    },
                },
                advice2: {
                    title: {
                        text1: "Faites des pauses",
                        text2: "tout au long de votre repas",
                    },
                    sub: {
                        text1: "Vous avez l'habitude de prendre votre repas dans de bonnes conditions (au moins 20 minutes, au calme, détendue, en pleine conscience...) et malgré cela vous avez encore envie de manger ? Peut-être devriez-vous faire une petite pause de 10 mn avant de manger votre dessert : l'entremets (ou le laitage) accompagné du fruit que vous aimez. Ceci afin de laisser le temps au cerveau de ressentir la satiété et d'être bien rassasié.e une fois que vous aurez mangé votre dessert.",
                    },
                },
                advice3: {
                    title: {
                        text1: "Dégustez",
                        text2: "chaque bouchée",
                    },
                    sub: {
                        text1: "On ne le dira jamais assez : déguster son repas contribue à activer la sensation de satiété. Ainsi, veillez à bien mâcher chaque bouchée. Posez vos couverts entre chacune d'elles pour vous assurer de prendre votre temps.",
                        text2: "Appréciez les saveurs de chaque aliment : est-ce salé ? Acide ? Amer ? ... Et la texute : est-molle ? Dure ? Croquante ? Craquante ? Plus vous renouvellerez cet exercice, plus vous prendrez le temps de manger et le ferez avec plaisir, plus vous serez rassasié.e à l'issue du repas.",
                    },
                },
                advice4: {
                    title: {
                        text1: "Quelques astuces",
                        text2: "pour prendre le temps de manger",
                    },
                    sub: {
                        text1: "Vous avez tendance à manger vite et à expédier vos repas en moins de 20 minutes ? Essayez de manger avec votre main non-dominante (la main gauche pour les droitier et la main droite pour les gauchers). Pas évident, n'est-ce pas ? Cela rend votre mouvement moins précis donc moins rapide... Vous pouvez également opter pour manger avec des baguettes chinoises ou une fourchette pour enfant...",
                        text2: "Vous avez tendance à manger en grosse quantité ? Remplacez votre assiette par une assiette à dessert. Ainsi, vous bernerez votre cerveau en lui donnant l'impression d'avoir une portion plus importante que la réalité.",
                        text3: "Essayez l'astuce qui vous tente, vous en verrez rapidement les bénéfices.",
                    },
                },
                advice5: {
                    title: {
                        text1: "Les légumes ",
                        text2: ": les alliés de votre satiété",
                    },
                    sub: {
                        text1: "Si vous ressentez le besoin de manger en sortant de table, peut-être n'avez vous pas consommé suffisamment de légumes... Ceux-ci agissent directement et durablement sur votre satiété, tout en apportant vitamines et minéraux dont votre organisme a besoin.",
                        text2: "Faibles en calories (sauf exception) ils peuvent être consommés en grande quantité. Pour obtenir 2 à 3 portions de légumes par jour (sur les 5 portions de fruits et de légumes par jour), ajoutez une assiette de crudités en entrée ou une salade verte, des légumes et en accompagnement de vos plats : une ratatouille, une poêlée de légumes ...",
                    },
                },
            },
            day: {
                advice1: {
                    title: {
                        text1: "Faites",
                        text2: "une coupure",
                        text3: "de fin de journée",
                    },
                    sub: {
                        text1: "Une fois votre journée terminée, accordez-vous un moment de coupure pour vous vider la tête et aborder sereinement votre soirée.",
                        text2: "Demandez à votre entourage de ne pas vous solliciter pendant 10 ou 15 mn. Optez pour un bon bain, allongez vous quelques minutes, écoutez de la musique qui vous détend, faites un peu de méditation, pratiquez une activité physique que vous aimez... Les possibilités sont nombreuses. Le seul mot d'ordre : faites ce qui vous fait du bien plutôt que de décompresser par la nourriture.",
                    },
                },
                advice2: {
                    title: {
                        text1: "Créer",
                        text2: "pour décompresser",
                    },
                    sub: {
                        text1: "Après une mauvaise journée, rien de tel que de se vider la tête. Les loisirs créatifs sont un bon moyen de se détendre et de générer la satisfaction d'avoir accompli quelque chose. A vous de choisir votre activité : dessin, tricot, scrapbooking, bricolage, écriture… Essayez plusieurs activités créatives pour trouver celle qui vous correspond le plus. Vous verrez qu'en plus de vous occuper l'esprit, elle aura un pouvoir méditatif sur vous. Ainsi, vous ne penserez plus à vous tourner vers la nourriture.",
                    },
                },
                advice3: {
                    title: {
                        text1: "Chouchouter son environnement",
                        text2: " pour se détendre",
                    },
                    sub: {
                        text1: "Un environnement \"chaotique\" peut provoquer du stress qui pousse à grignoter. Veillez à ce que votre foyer soit toujours rangé, afin qu'en rentrant chez vous, vous vous sentiez calme et décontracté.e. De plus, en prenant l'habitude d'effectuer les tâches ménagères régulièrement, non seulement vous libérerez votre charge mentale de tout ce que vous avez à faire, mais en plus, les tâches vous paraîtront moins lourdes si elles sont effectuées au fur et à mesure. Enfin, sachez que le rangement et le ménage constituent une forme de méditation en mouvement qui apaise l'esprit.",
                    },
                },
                advice4: {
                    title: {
                        text1: "Remettre",
                        text2: "du positivisme",
                        text3: "dans sa journée",
                    },
                    sub: {
                        text1: "L'être humain à cette fâcheuse tendance à se focaliser sur les évènements négatifs, même s'ils ne sont pas majoritaires. Vous avez l'impression d'avoir passé une mauvaise journée ? Observez-la en détail, et faites en ressortir le positif.",
                        text2: "Détendez-vous, prenez un papier et un stylo puis faites la liste de vos petites victoires de la journée. Quelques exemples : vous avez suivi le programme sans écarts, vous avez réussi à faire plus d'activité physique, vous avez rendu service à quelqu'un, vous avez réussi à boire plus d'eau, vous avez été à l'écoute d'un proche, vous êtes allé au bout d'une tâche... Allez dans le détail et soyez fier.e des moindre détails.",
                        text3: "Vous ne trouvez rien de positif ? Cherchez bien ! Et puis, la journée n'est pas terminée...",
                    },
                },
                advice5: {
                    title: {
                        text1: "Trouver et répéter",
                        text2: " son mantra",
                    },
                    sub: {
                        text1: "Un mantra est une affirmation positive que l'on se répète et qui fait du bien physiquement et mentalement. Une phrase bien à vous qui vous motive, qui vous apporte une belle estime de vous, de la satisfaction, de la joie, du courage, de la fierté. En vous le répétant régulièrement, vous apaiserez vos tensions, vous chasserez vos pensées négatives, vous retrouverez de la confiance en vous...",
                        text2: "Voici quelques exemples de mantra :",
                        text3: "Je m’aime et je m’accepte telle que je suis.",
                        text4: "Je suis libre d’être moi-même.",
                        text5: "J’ai confiance en moi et j’ai confiance en la vie.",
                        text6: "Je suis courageux.se.",
                        text7: "Je vais de mieux en mieux.",
                        text8: "À vous de trouver celui qui vous correspond et de vous le répéter en conscience plusieurs fois par jour.",
                    },
                },
            },
            sleep: {
                advice1: {
                    title: {
                        text1: "Se préparer",
                        text2: "à l'endormissement",
                    },
                    sub: {
                        text1: "Un sommeil trop court ou de mauvaise qualité perturbe l'équilibre des hormones de l'appétit et de la satiété. Pour vous préparer à l'endormissement, instaurez un rituel de détente : prenez une douche tiède ou un bain relaxant, massez-vos épaules, vos mains, vos pieds et préférez des lumières douces ou tamisées. Respectez les premiers signes d'endormissement : dès que vous ressentez des frissons, les paupières lourdes, ou que vous baillez, c'est qu'il est l'heure d'aller vous coucher.",
                    },
                },
                advice2: {
                    title: {
                        text1: "Boire une tisane",
                        text2: "pour préparer son corps à dormir",
                    },
                    sub: {
                        text1: "Le simple geste de boire une tisane instaure un rituel de coucher en indiquant au cerveau que c'est bientôt l'heure de dormir en favorisant une baisse de la température corporelle. Des tisanes peuvent vous y aider grâce à leurs propriétés relaxantes : la verveine, la valériane, la passiflore ou la fleur d’aubépines. Toutefois, si cela vous oblige à vous lever la nuit, ne la buvez pas juste avant d'aller vous coucher.",
                    },
                },
                advice3: {
                    title: {
                        text1: "Préparer son cocon",
                        text2: " pour bien dormir",
                    },
                    sub: {
                        text1: "Pour garantir une meilleure nuit de sommeil, préparez votre cocon : dormez dans une pièce dont la température n'est ni trop froide, ni trop chaude. 19° étant l'idéal. Fermez les volets ou choisissez des rideaux opaques. Tous ces signaux indiquent au cerveau qu'il fait nuit et donc qu'il faut dormir. Mettez votre téléphone en mode avion pour éviter d'être réveillé.e par des notifications. Également, évitez toute source de lumière artificielle, comme celle du radio réveil, qui pourrait perturber votre sommeil.",
                    },
                },
                advice4: {
                    title: {
                        text1: "pour s'endormir",
                        text2: "Méditer",
                    },
                    sub: {
                        text1: "Vous avez votre rituel du soir pour préparer votre corps à l'endormissement, votre chambre est à la bonne température, vous avez coupé les écrans 2h avant de vous coucher, vous avez mangé un repas équilibré au dîner et pourtant vous tournez dans votre lit et ne parvenez pas à vous endormir ou à vous rendormir ? Essayez le scan corporel. Très efficace, cette méditation vous permet de trouver rapidement le sommeil.",
                        text2: "Allongez-vous sur le dos, les bras un peu éloignés du corps avec les paumes vers le haut, et les jambes légèrement espacées et détendez tout votre corps.",
                        text3: "Gardez vos yeux fermés et scannez votre corps petit à petit en commençant par les pieds. Évaluez mentalement si vous ressentez du confort ou de l'inconfort. Puis faites la même chose pour vos tibias, vos mollet, vos genoux... Remontez votre attention lentement, sur chaque partie de votre corps jusqu'à la tête... Mais il y a de fortes chances pour que vous soyez déjà endormi.e avant d'y parvenir !",
                    },
                },
                advice5: {
                    title: {
                        text1: "Éviter le café après 16h",
                        text2: "pour favoriser l'endormissement",
                    },
                    sub: {
                        text1: "C'est bien connu, la caféine présente des effets néfastes sur le sommeil. C'est pourquoi, il est nécessaire d'éviter le café, les boissons énergisantes, le cola et le thé noir après 16h, voire même 14h pour les personnes les plus sensibles aux effets de la caféine.",
                        text2: "Au-delà de cette heure butoir de consommation, il est également nécessaire de se limiter à 3-4 tasses par jour.",
                    },
                },
            },
            boring: {
                advice1: {
                    title: {
                        text1: "S'ennuyer stimule",
                        text2: "la créativité",
                    },
                    sub: {
                        text1: "Dans un monde tel que le nôtre, qui va à 100 à l'heure, trouver le temps de s'ennuyer n'est pas évident. Vous pouvez donc vous réjouir de vous trouver dans cette situation. En effet, l’ennui pourrait stimuler votre productivité et votre créativité.",
                        text2: "Sandi Mann, maître de conférence en psychologie, a déclaré que \"L’ennui repose sur la recherche d’une simulation neuronale qui n’est pas satisfaite\". Elle explique que lorsque nous ne trouvons pas de stimulus, notre esprit en créer un.",
                        text3: "Cette créativité spontanée, en laissant vagabonder son esprit, permettrait également de trouver des solutions à nos problèmes. Voyez donc ce moment comme une précieuse occasion de vous ressourcer et de rêvasser (loin des écrans).",
                    },
                },
                advice2: {
                    title: {
                        text1: "Comprendre",
                        text2: "son ennui",
                    },
                    sub: {
                        text1: "Vous vous ennuyez et cette sensation vous est désagréable ? Demandez-vous pourquoi. Est-ce la pression de la société qui vous pèse sur le besoin d'être constamment actif.ve ? Est-ce parce que vous craignez le silence et la solitude ? Est-ce parce que l'ennui vous donne l'impression de perdre du temps ? Est-ce parce que vous avez des tâches qui ne vous motivent pas à effectuer ?",
                        text2: "Prenez-le temps de réfléchir avec bienveillance à ce que vous inspire l'ennui et à ce qui l'a provoqué. Prêtez-vous au jeu en détaillant vos ressentis. Vous découvrirez alors tout ce que l'ennui peut vous apprendre sur vous-même...",
                    },
                },
                advice3: {
                    title: {
                        text1: "Que feriez-vous",
                        text2: "si vous ne vous ennuyez pas ?",
                    },
                    sub: {
                        text1: "L'ennui est là et manifestement, cela ne vous convient pas. Prenez un papier et un stylo et notez les activités que vous aimeriez faire plutôt que de vous ennuyer. Une fois cette liste effectuée, notez en face de chacune des activités ce qui vous empêche de les pratiquer. S'il y en a pour lesquelles vous ne rencontrez pas d'obstacle, faites-les dès maintenant ! Quant aux autres, trouvez des alternatives.",
                        text2: "À la fin de cet exercice, prenez conscience d'une chose : l'ennui vous aura permis de faire preuve de créativité pour trouver quelque chose à faire :)",
                    },
                },
                advice4: {
                    title: {
                        text1: "Abandonnez-vous",
                        text2: "à l'ennui",
                    },
                    sub: {
                        text1: "L'ennui est souvent perçu comme un état négatif par nos contemporains. À tort ! Dans les vies mouvementées que nous menons à notre époque, il est rare d'avoir du temps de prendre le temps ! Hors l'ennui génère un espace de questionnements et nous permet d'intégrer notre vie dans sa réalité. C’est parce que l’ennui va nous enrichir de nous-mêmes qu’il peut devenir un endroit de construction du bonheur.",
                        text2: "Essayez de ne pas résister à l'ennui. Installez-vous dans un fauteuil, ou un canapé et laissez-vous envahir par l'ennui de sorte qu'il constitue un véritable lâcher prise. Laissez vagabonder votre esprit, rêvassez, questionnez, pensez... Au plus vous apprivoiserez votre ennui, au plus vous le verrez comme quelque chose de positif.",
                    },
                },
                advice5: {
                    title: {
                        text1: "Quand l'ennui génère de",
                        text2: "l'altruisme",
                    },
                    sub: {
                        text1: "L'ennui, loin d'être synonyme de paresse se défini plutôt comme une incapacité physique ou psychique à se concentrer totalement, ce qui mobilise toute notre attention et nous frustre.",
                        text2: "Mais l'ennui à du bon ! Une étude a montré que dans certains cas, il poussait à réaliser des actions altruistes. En effet, confronté à une situation sans intérêt, qui génère peu de motivation et de satisfaction, nous sommes poussés à essayer de retrouver du sens et donc à nous engager dans des actions altruistes.",
                        text3: "Alors ? Vers quelle action altruiste va vous pousser votre ennui ? :)",
                    },
                },
            },
            screen: {
                advice1: {
                    title: {
                        text1: "Changer de rituel",
                        text2: "devant la télé",
                    },
                    sub: {
                        text1: "Vous avez l'habitude de grignoter quelque chose devant la télé, à la même heure chaque jour, que vous ressentiez ou non de la faim ? Essayez de changer cette habitude en remplaçant votre aliment plaisir par une tisane, par exemple. En répétant ce nouveau rituel, vous adopterez une nouvelle bonne habitude sans même vous en rendre compte !",
                    },
                },
                advice2: {
                    title: {
                        text1: "Soyez actif.ve",
                        text2: "dans vos occupations",
                    },
                    sub: {
                        text1: "Les écrans font bien plus que nous divertir : ils détournent notre attention et peuvent créer des automatismes tels que grignoter en regardant une série ou en consultant son fil d'actualité sur son téléphone... C'est pourquoi, dans la mesure du possible, essayez de limiter votre temps de passivité devant les écrans. Trouvez d'autres alternatives pour vous occuper, telles que la lecture ou des passe-temps créatifs. Plus vous serez actif.ve, plus votre cerveau sera occupé à autre chose que de vouloir grignoter...",
                    },
                },
                advice3: {
                    title: {
                        text1: "Manger ou regarder ses écrans",
                        text2: "il faut choisir",
                    },
                    sub: {
                        text1: "En mangeant devant la télé, le téléphone ou l'ordinateur, le cerveau n'est plus concentré sur l'acte de manger. Ainsi, la sensation de satiété (l'état de non-faim) est reléguée au second plan et n'est pas ressentie comme elle le devrait. Résultat : vous pouvez manger jusqu'à 25% de plus que la normale !",
                        text2: "Lors de votre prochain repas, veillez à couper vos écrans, à vous asseoir à table et à manger tranquillement avec toute la présence et la conscience nécessaires à ce qu'il y a dans votre assiette. Cela vous permettra de ressentir votre satiété et votre rassasiement et de vous éviter des craquages.",
                    },
                },
                advice4: {
                    title: {
                        text1: "Fuyez",
                        text2: " la publicité",
                    },
                    sub: {
                        text1: "Vous avez vu une pub à la télé qui vous donne soudainement envie de craquer pour une douceur ? C'est que les équipes marketing ont bien fait leur travail ! Pour autant, sachez qu'une envie de grignoter ne dure que 15 minutes maximum. Prenez un verre d'eau, patientez et vous verrez que l'envie vous sera passée. Pour la suite, n'hésitez pas à vaquer à une occupation le temps de la publicité, voir même de couper le son de la télévision pour ne pas être à nouveau tenté.e.",
                    },
                },
                advice5: {
                    title: {
                        text1: "Actif.ve",
                        text2: " devant la télé",
                    },
                    sub: {
                        text1: "La passivité devant les écrans provoque des envies de grignotage qui peuvent générer des prise de poids et augmenter le temps de passivité… Pour sortir de ce cercle vicieux, trouvez une activité à pratiquer pendant que vous regardez la télé : faire du repassage, de la couture, du macramé... Ou même du stepper, pour augmenter votre nombre de pas de la journée !",
                    },
                },
            },
            holidays: {
                advice1: {
                    title: {
                        text1: "Reproduire",
                        text2: " un cadre propice aux bonnes habitudes",
                    },
                    sub: {
                        text1: "Vous êtes dans un environnement différent du vôtre et vous avez le sentiment de ne pas réussir à suivre les bonnes habitudes prises chez vous ? C'est normal. Mais avec quelques petites touches personnelles, vous pouvez facilement reproduire le même cadre que chez vous : posez une belle corbeille de fruits sur la table du salon ou de la cuisine, rangez les confiseries des uns et des autres au fond du placard, mettez les crudités en avant dans le frigidaire... En reproduisant ce cadre, vous envoyez le même signal à votre cerveau qui vous permet de garder le comportement lié à vos bonnes habitudes. Cela vous évitera de céder au grignotage. Et, lors d'un dîner ? Asseyez-vous à côté d'un petit mangeur. ;)",
                    },
                },
                advice2: {
                    title: {
                        text1: "S'organiser",
                        text2: "en vacances",
                    },
                    sub: {
                        text1: "S'organiser, loin d'être une punition est une opportunité de prévoir à l'avance les repas sur quelques jours pour limiter les grignotages intempestifs.",
                        text2: "Prévoyez les différents repas de la semaine : petits sandwichs, salades composées, salades de fruits... Cela vous permettra de cadrer vos repas sans être poussé.e par la faim des aliments faciles tels que les chips, les biscuits apéro, les confiseries, etc...",
                        text3: "Puis faites la liste des courses. La préparer à l'avance vous évitera d'acheter par compulsion des aliments gras, salés ou sucrés.",
                    },
                },
                advice3: {
                    title: {
                        text1: "Profiter des vacances pour",
                        text2: " prendre soin de soi",
                    },
                    sub: {
                        text1: "Les vacances constituent le moment idéal pour mieux dormir, optimiser sa forme, prendre du temps pour s'occuper de soi. Faites des grandes ballades en forêt, marchez le long de la plage, essayez une nouvelle activité physique. Profitez-en pour vous tonifier et ressentir de bonnes sensations en les gardant en tête pour les continuer à votre retour de vacances. Si vous ressentez que vous avez plus faim, c'est normal, vous vous dépensez plus que d'habitude, le corps vous indique juste qu'il a des besoins supplémentaires. Ayez toujours une collation saine sur vous et buvez bien dans la journée.",
                    },
                },
                advice4: {
                    title: {
                        text1: "Adopter",
                        text2: "le rythme de vacances",
                        text3: "qui ne fera pas reprendre de poids",
                    },
                    sub: {
                        text1: "En vacances, nous sommes souvent amené.e à manger des aliments qui nous font plaisir ou à boire un verre de vin plus régulièrement. C'est normal ! En revanche, veillez garder vos rythmes de repas et faites en sorte qu'ils soient équilibrés si vous mangez librement. Veillez également à la taille des portions que vous consommez et réduisez autant que possible la quantité d'aliments gras, salés ou sucrés que vous consommez.",
                        text2: "Pratiquez une activité physique, dépensez-vous un peu plus que d'habitude. De cette manière, vous ne perdrez pas de poids, mais surtout vous n'en prendrez pas. Vous pourrez ainsi reprendre sereinement votre programme à votre retour de vacances, sans culpabiliser !",
                    },
                },
                advice5: {
                    title: {
                        text1: "Se mettre aux fourneaux",
                        text2: "en vacances",
                    },
                    sub: {
                        text1: "Avez-vous remarqué qu'en faisant la cuisine pour tout le monde on est moins affamé.e une fois à table ? En effet, le simple fait de manipuler et de sentir les aliments régule l'appétit.",
                        text2: "Passez donc en cuisine et profitez pleinement des aliments de saison avec des légumes (ratatouille, entrées de crudités) et des fruits (salades de fruits).",
                        text3: "L'été c'est facile, direz-vous ? Ce n'est pas faux.",
                        text4: "Toutefois, en hiver, de nombreuses recettes gourmandes mais healthy existent : vous pouvez vous tournez vers les plats mijotés parfumés avec des herbes, les soupes maison, les risottos... Tant que vous utilisez les matière grasses avec parcimonie et que vous vous servez des portions raisonnables !",
                        text5: "Et la raclette ? Vous pouvez bien sûr vous en autoriser une à l'occasion, et pourquoi ne pas la revisiter en remplaçant les pommes de terre par des pommes crues pour mélanger les saveurs et les textures ? Vous pouvez aussi ajouter des légumes grillés ou une salade verte pour une meilleure digestion.",
                    },
                },
            },
            making: {
                advice1: {
                    title: {
                        text1: "S'organiser",
                        text2: "pendant la préparation de vos repas",
                    },
                    sub: {
                        text1: "Préparer le repas peut être propice à la tentation, c'est pourquoi il est important d'organiser votre frigo en mettant au premier plan des tomates cerises, des bébés carottes et des radis que vous pouvez mâcher en faisant la cuisine sans que cela ne perturbe votre programme et le repas qui va suivre.",
                    },
                },
                advice2: {
                    title: {
                        text1: "Eviter d'être affamé.e",
                        text2: "avant de préparer le repas",
                    },
                    sub: {
                        text1: "Difficile de lutter contre la tentation quand on est affamé.e. C'est pourquoi, prendre votre petit déjeuner est important pour éviter la tentation lors de la préparation du déjeuner, et prendre votre collation l'est tout autant pour ne pas craquer lors de la préparation du dîner.",
                        text2: "Si malgré ça le besoin de manger se fait sentir, ayez toujours à disposition des aliments qui peuvent vous faire patienter sans vous couper la faim avant votre repas : des crudités (radis, tomates, carotte, concombre...) ou 1 yaourt, 1 tranche de jambon, de volaille, de bresaola, de viande des grisons. Et surtout : évitez les biscuits sec, le fromage, le pain avec du beurre et tout autre tentation qui pourrait perturber votre programme et votre repas à suivre...",
                    },
                },
                advice3: {
                    title: {
                        text1: "Éloigner les tentations",
                        text2: "pour ne pas y succomber.",
                    },
                    sub: {
                        text1: "Lorsque vous préparez le repas, soyez complétement dédié.e à ce que vous faites. Ne pensez à rien d'autres que les aliments dont vous avez besoin pour faire la cuisine et veillez à garder à distances tous les aliments qui peuvent vous poussez au grignotage tels que le pain, les chips, les apéritifs,...",
                        text2: "Organisez vos placard de sorte que tous ces aliments ne soient pas facilement accessibles en les rangeant au fond.",
                    },
                },
                advice4: {
                    title: {
                        text1: "Soyez",
                        text2: "actif.ve",
                        text3: "et",
                        text4: "creatif.ve",
                    },
                    sub: {
                        text1: "Quand vous préparez le repas, plutôt que d'attendre dans la cuisine que les plats mijotent en grignotant par-ci par-là sans vous en rendre compte, préparez une jolie table, rangez la cuisine au fur et à mesure, passez un coup de balais... En enchainant les actions vous ne penserez plus à picorer et l'heure de passer à table arrivera plus rapidement !",
                    },
                },
                advice5: {
                    title: {
                        text1: "S'occuper",
                        text2: "quand on cuisine",
                    },
                    sub: {
                        text1: "Peut-être avez-vous l'habitude de picorer pour passer le temps pendant que vous préparer le repas. Si c'est le cas, réfléchissez à ce que vous pourriez faire à la place : écouter la radio, un podcast, appeler un.e proche... Ainsi, vous allierez la préparation du repas à une activité qui deviendra votre nouvelle habitude.",
                    },
                },
            },
            loss: {
                advice1: {
                    title: {
                        text1: "Relâchez la pression",
                        text2: "que vous vous imposez",
                    },
                    sub: {
                        text1: "Vous avez l'impression que vous n'atteindrez jamais votre objectif ? Rappelez-vous que chacun a un métabolisme qui lui est propre. Votre perte de poids vous appartient et vous la menez comme vous voulez et surtout, comme vous pouvez. Essayez d'accorder moins d'importance aux obstacles que vous rencontrez car ils vous éloignent de ce que vous désirez vraiment : perdre du poids pour vous sentir mieux dans votre corps. Vous y arriverez ! Soyez acteur.trice de votre changement. C'est avec de bonnes habitudes alimentaires et une activité physique régulière que vous parviendrez à modifier votre silhouette pour longtemps. Courage !",
                    },
                },
                advice2: {
                    title: {
                        text1: "Regardez tout",
                        text2: "le chemin parcouru",
                    },
                    sub: {
                        text1: "La perte de poids n'est pas linéaire et vous pouvez rencontrer des paliers durant le programme. Rassurez-vous, c'est tout à fait normal. Au cours de ces paliers, il est important de ne pas se démotiver et de continuer votre programme habituel. Se laisser-aller sur des aliments plaisirs ne fera que rallonger cette période de stagnation et alimenter votre perte de motivation. Votre perte de poids s'apparente à une randonnée en montage avec des embuches, des dénivelés mais aussi des chemins plus reposants. Vous avez montré que vous aviez l'énergie nécessaire pour y arriver en changeant d'habitudes alimentaires alors soyez certain.e que vous parviendrez à gravir l'Everest !",
                    },
                },
                advice3: {
                    title: {
                        text1: "Bougez et",
                        text2: "passez à l'action",
                    },
                    sub: {
                        text1: "Beaucoup de facteurs peuvent ralentir ou bloquer la perte de poids : le métabolisme, l'âge, la santé... Et la sédentarité. Or pratiquer une activité physique a de nombreux avantages : augmentation des dépenses énergétiques (donc aide à la perte de poids), maintien d'une bonne masse musculaire, bien-être psychologique... De plus, lorsque vous vous dépensez, vous n'êtes plus tenter de grignoter.",
                        text2: "Il ne vous reste plus qu'à trouver une activité physique qui vous plaise et qui soit adaptée à vous !",
                        text3: "Vous pouvez dès à présent commencer en douceur avec de la marche ou avec les exercices de fitness proposées sur notre application mobile COMME J'AIME.",
                        text4: "C'est parti ?",
                    },
                },
                advice4: {
                    title: {
                        text1: "Reconnectez-vous",
                        text2: "avec votre corps",
                    },
                    sub: {
                        text1: "Vous ne perdez plus de poids ? Pas de panique, plusieurs facteurs peuvent expliquer cela.",
                        text2: "Commencez par vous demander si vous avez modifié quelque chose dans votre quotidien :",
                        text3: "Buvez-vous moins d'eau qu'au départ ?",
                        text4: "Votre sommeil est-il de bonne qualité ?",
                        text5: "Vous-êtes vous remis.e à l'activité physique ? Si c'est le cas, votre poids ne change peut-être pas sur la balance mais c'est parce que vous gagnez en masse musculaire.",
                        text6: "Avez vous eu une contrariété, un stress durant cette période ?",
                        text7: "Votre transit est-il régulier ?",
                        text8: "Analyser en détail vos habitudes du quotidien vous permettra de mieux comprendre la stagnation de votre poids et d'agir en conséquence. N'oubliez pas que parfois, le corps a simplement besoin de temps pour intégrer les changements qui s'opèrent sur vous. Faites vous confiance, accrochez-vous, vous allez y arriver !",
                    },
                },
                advice5: {
                    title: {
                        text1: "Adopter",
                        text2: "de bonnes habitudes",
                    },
                    sub: {
                        text1: "Adopter une bonne hygiène de vie de manière générale est indispensable pour atteindre votre objectif et le maintenir sur le long terme. Et cela passe par de nouvelles habitudes aussi bien alimentaires, que physique et de bien-être. Prenez un stylo et notez les \"mauvaises habitudes\" qui constituent votre quotidien. Notez celles que vous souhaiteriez modifier en priorité puis rendez-vous sur l'application mobile COMME J'AIME pour trouver les bonnes habitudes par lesquelles vous souhaiteriez les modifier. De cette manière, en seulement 28 jours, vous adopterez de nouveaux automatismes qui vous aideront à poursuivre votre perte de poids...",
                    },
                },
            },
            working: {
                advice1: {
                    title: {
                        text1: "Faites de votre pause,",
                        text2: " un moment de détente",
                    },
                    sub: {
                        text1: "Lorsque vous ressentez le besoin de manger pendant que vous travaillez, cela peut tout simplement signifier que vous avez besoin d'une pause. Prenez un café, un thé ou un verre d'eau, faites quelques pas, échangez avec un.e collègue ou appelez un.e ami.e. Vous vous rendrez compte que vous n'avez pas réellement besoin de manger, mais simplement besoin de souffler...",
                    },
                },
                advice2: {
                    title: {
                        text1: "Décompresser par",
                        text2: "la respiration",
                    },
                    sub: {
                        text1: "Le stress, la fatigue, le sentiment d'incompréhension, l'énervement, tout ceci peut vous procurer des besoins de grignoter pour seul réconfort. Ces besoins sont généralement brefs et peuvent s'évaporer si vous les laisser passer. Ayez le réflexe de respirer et de relâcher la pression. Assis(e) ou debout, inspirez lentement (le ventre se gonfle) et expirez (le ventre se dégonfle), faites le 6 fois de suite. Vous pouvez utiliser cette technique n'importe où, n'importe quand.",
                    },
                },
                advice3: {
                    title: {
                        text1: "Transformer le grignotage en",
                        text2: "collation",
                    },
                    sub: {
                        text1: "Vos craquages sont réguliers au travail ? Organisez-les de sorte qu'ils s'apparentent à une collation et non à un craquage. Apportez un fruit, un yaourt nature, une tranche de dinde ou votre barre COMME J'AIME pour le goûter. Ils vous redonneront l'énergie nécessaire pour poursuivre sereinement votre journée sans culpabiliser d'avoir craqué sur un aliment gras, trop sucré ou trop salé.",
                    },
                },
                advice4: {
                    title: {
                        text1: "Quand",
                        text2: "la tentation",
                        text3: "est quotidienne",
                    },
                    sub: {
                        text1: "Nous avons tous un.e ou des collègues qui ont la gentillesse de faire un gâteau ou d'apporter régulièrement des viennoiseries au bureau. Dans ce contexte, vous ressentez peut-être des difficultés à résister. Est-ce par peur d'être mal.e vu.e ? De devoir justifier que vous êtes en période d'amaigrissement ? De vous exclure socialement ?",
                        text2: "Posez-vous la question de savoir si vous en avez réellement besoin. Non ? Alors offrez votre plus beau sourire pour gentiment dire \"non, merci\" et votre collègue passera à la personne suivante. Si cela vous semble difficile, retournez à votre tâche et vous n'y penserez même plus.... Vous serez même fier.e de vous !",
                    },
                },
                advice5: {
                    title: {
                        text1: "Ranger votre bureau",
                        text2: "pour éviter les tentations",
                    },
                    sub: {
                        text1: "Le désordre peut parfois occasionner un sentiment de stress et de perte de contrôle. Pour éviter de grignoter, commencez par ranger votre bureau et vous créer un petit cocon de travail avec des photos, des cartes postales, des phrases motivantes... Vous travaillerez alors dans un contexte plus serein et apaisant.",
                        text2: "Rangez également toutes les tentations (biscuits, bonbons, ...) dans un tiroir que vous n'ouvrez que rarement et posez une bouteille d'eau sur votre bureau pour penser à boire régulièrement.",
                    },
                },
            }
        },
    },
    email: {
        conf: "Votre demande a été transmise à votre coach"
    }
}