import FatigueAdvice1 from "./fatigue/fatigueAdvice1";
import FatigueAdvice2 from "./fatigue/fatigueAdvice2";
import FatigueAdvice3 from "./fatigue/fatigueAdvice3";
import FatigueAdvice4 from "./fatigue/fatigueAdvice4";
import FatigueAdvice5 from "./fatigue/fatigueAdvice5";
import FatigueAdvice6 from "./fatigue/fatigueAdvice6";
import FatigueAdvice7 from "./fatigue/fatigueAdvice7";
import FatigueAdvice8 from "./fatigue/fatigueAdvice8";
import AloneAdvice1 from "./alone/aloneAdvice1";
import AloneAdvice2 from "./alone/aloneAdvice2";
import AloneAdvice3 from "./alone/aloneAdvice3";
import AloneAdvice4 from "./alone/aloneAdvice4";
import AloneAdvice5 from "./alone/aloneAdvice5";
import AloneAdvice6 from "./alone/aloneAdvice6";
import AloneAdvice7 from "./alone/aloneAdvice7";
import AloneAdvice8 from "./alone/aloneAdvice8";
import AngryAdvice1 from "./angry/angryAdvice1";
import AngryAdvice2 from "./angry/angryAdvice2";
import AngryAdvice3 from "./angry/angryAdvice3";
import AngryAdvice4 from "./angry/angryAdvice4";
import AngryAdvice5 from "./angry/angryAdvice5";
import AngryAdvice6 from "./angry/angryAdvice6";
import AnxietyAdvice1 from "./anxiety/anxietyAdvice1";
import AnxietyAdvice2 from "./anxiety/anxietyAdvice2";
import AnxietyAdvice3 from "./anxiety/anxietyAdvice3";
import AnxietyAdvice4 from "./anxiety/anxietyAdvice4";
import AnxietyAdvice5 from "./anxiety/anxietyAdvice5";
import AnxietyAdvice6 from "./anxiety/anxietyAdvice6";
import DemotivationAdvice1 from "./demotivation/demotivationAdvice1";
import DemotivationAdvice2 from "./demotivation/demotivationAdvice2";
import DemotivationAdvice3 from "./demotivation/demotivationAdvice3";
import DemotivationAdvice4 from "./demotivation/demotivationAdvice4";
import DemotivationAdvice5 from "./demotivation/demotivationAdvice5";
import FrustrationAdvice1 from "./frustation/frustrationAdvice1";
import FrustrationAdvice2 from "./frustation/frustrationAdvice2";
import FrustrationAdvice3 from "./frustation/frustrationAdvice3";
import FrustrationAdvice4 from "./frustation/frustrationAdvice4";
import FrustrationAdvice5 from "./frustation/frustrationAdvice5";
import HappyAdvice1 from "./happy/happyAdvice1";
import HappyAdvice2 from "./happy/happyAdvice2";
import HappyAdvice3 from "./happy/happyAdvice3";
import HappyAdvice4 from "./happy/happyAdvice4";
import HappyAdvice5 from "./happy/happyAdvice5";
import HappyAdvice6 from "./happy/happyAdvice6";
import OverflowAdvice1 from "./overflow/overflowAdvice1";
import OverflowAdvice2 from "./overflow/overflowAdvice2";
import OverflowAdvice3 from "./overflow/overflowAdvice3";
import OverflowAdvice4 from "./overflow/overflowAdvice4";
import OverflowAdvice5 from "./overflow/overflowAdvice5";
import OverflowAdvice6 from "./overflow/overflowAdvice6";
import SadeAdvice1 from "./sade/sadeAdvice1";
import SadeAdvice2 from "./sade/sadeAdvice2";
import SadeAdvice3 from "./sade/sadeAdvice3";
import SadeAdvice4 from "./sade/sadeAdvice4";
import SadeAdvice5 from "./sade/sadeAdvice5";
import SadeAdvice6 from "./sade/sadeAdvice6";
import StressAdvice1 from "./stress/stressAdvice1";
import StressAdvice2 from "./stress/stressAdvice2";
import StressAdvice4 from "./stress/stressAdvice4";
import StressAdvice6 from "./stress/stressAdvice6";
import StressAdvice7 from "./stress/stressAdvice7";
import StressAdvice8 from "./stress/stressAdvice8";
import StressAdvice3 from "./stress/stressAdvice3";
import StressAdvice5 from "./stress/stressAdvice5";

const aloneListResponse = [
    <AloneAdvice1 />,
    <AloneAdvice2 />,
    <AloneAdvice3 />,
    <AloneAdvice4 />,
    <AloneAdvice5 />,
    <AloneAdvice6 />,
    <AloneAdvice7 />,
    <AloneAdvice8 />,
]

const angryListResponse = [
    <AngryAdvice1 />,
    <AngryAdvice2 />,
    <AngryAdvice3 />,
    <AngryAdvice4 />,
    <AngryAdvice5 />,
    <AngryAdvice6 />,
]

const anxietyListResponse = [
    <AnxietyAdvice1 />,
    <AnxietyAdvice2 />,
    <AnxietyAdvice3 />,
    <AnxietyAdvice4 />,
    <AnxietyAdvice5 />,
    <AnxietyAdvice6 />,
]

const demotivationListResponse = [
    <DemotivationAdvice1 />,
    <DemotivationAdvice2 />,
    <DemotivationAdvice3 />,
    <DemotivationAdvice4 />,
    <DemotivationAdvice5 />,
]

const fatigueListResponses = [
    <FatigueAdvice1 />,
    <FatigueAdvice2 />,
    <FatigueAdvice3 />,
    <FatigueAdvice4 />,
    <FatigueAdvice5 />,
    <FatigueAdvice6 />,
    <FatigueAdvice7 />,
    <FatigueAdvice8 />,
]

const frustrationListResponse = [
    <FrustrationAdvice1 />,
    <FrustrationAdvice2 />,
    <FrustrationAdvice3 />,
    <FrustrationAdvice4 />,
    <FrustrationAdvice5 />,
]

const happyListResponse = [
    <HappyAdvice1 />,
    <HappyAdvice2 />,
    <HappyAdvice3 />,
    <HappyAdvice4 />,
    <HappyAdvice5 />,
    <HappyAdvice6 />,
]

const overflowListResponse = [
    <OverflowAdvice1 />,
    <OverflowAdvice2 />,
    <OverflowAdvice3 />,
    <OverflowAdvice4 />,
    <OverflowAdvice5 />,
    <OverflowAdvice6 />,
]

const sadeListResponse = [
    <SadeAdvice1 />,
    <SadeAdvice2 />,
    <SadeAdvice3 />,
    <SadeAdvice4 />,
    <SadeAdvice5 />,
    <SadeAdvice6 />,
]


const stressListResponse = [
    <StressAdvice1 />,
    <StressAdvice2 />,
    <StressAdvice3 />,
    <StressAdvice4 />,
    <StressAdvice5 />,
    <StressAdvice6 />,
    <StressAdvice7 />,
    <StressAdvice8 />,
]

const response3Handler = (response) => {

    let response3 = [];

    if( response.R === 3 && response.value.some((el) => el === 1) ){
        // response3.push(<FatigueAdvice7 />)
        response3.push(fatigueListResponses[Math.floor(Math.random() * fatigueListResponses.length)])
    }

    if( response.R === 3 && response.value.some((el) => el === 2) ){
         // response3.push(<AloneAdvice8 />)
         response3.push(aloneListResponse[Math.floor(Math.random() * aloneListResponse.length)])
     }

    if( response.R === 3 && response.value.some((el) => el === 3) ){
        // response3.push(<FrustrationAdvice5 />)
        response3.push(frustrationListResponse[Math.floor(Math.random() * frustrationListResponse.length)])
    }

    if( response.R === 3 && response.value.some((el) => el === 4) ){
        // response3.push(<DemotivationAdvice5 />)
        response3.push(demotivationListResponse[Math.floor(Math.random() * demotivationListResponse.length)])
    }

    if( response.R === 3 && response.value.some((el) => el === 5) ){
        // response3.push(<AngryAdvice6 />)
        response3.push(angryListResponse[Math.floor(Math.random() * angryListResponse.length)])
    }

    if( response.R === 3 && response.value.some((el) => el === 6) ){
        // response3.push(<StressAdvice8 />)
        response3.push(stressListResponse[Math.floor(Math.random() * stressListResponse.length)])
    }

    if( response.R === 3 && response.value.some((el) => el === 7) ){
        // response3.push(<SadeAdvice6 />)
        response3.push(sadeListResponse[Math.floor(Math.random() * sadeListResponse.length)])
    }

    if( response.R === 3 && response.value.some((el) => el === 8) ){
        // response3.push(<OverflowAdvice6 />)
        response3.push(overflowListResponse[Math.floor(Math.random() * overflowListResponse.length)])
    }

    if( response.R === 3 && response.value.some((el) => el === 9) ){
        // response3.push(<AnxietyAdvice6 />)
        response3.push(anxietyListResponse[Math.floor(Math.random() * anxietyListResponse.length)])
    }

    if( response.R === 3 && response.value.some((el) => el === 10) ){
        // response3.push(<HappyAdvice6 />)
        response3.push(happyListResponse[Math.floor(Math.random() * happyListResponse.length)])
    }

    if( response.R === 3 && response.value.length === 0 ){
         return {content: "Pas de réponse"}
     }

    return { R: response.R, content: response3 }
}

export default response3Handler;