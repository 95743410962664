import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import {sosBreakingActions} from "../../../_actions/sosBreakingActions";
import {useDispatch, useSelector} from "react-redux";
import {sosBreakingStep} from "../../../_constants/sosBreakingConstants";
import NoBreakComponent from "../modalContentComponent/noBreakComponent";
import BigBreakComponent from "../modalContentComponent/bigBreakComponent";
import {emailFactoryComponent} from "../emailFactoryComponent";
import {TIMEOUT_DURATION} from "../../../_constants";
import ModalEmailConfirmationComponent from "./modalEmailConfirmationComponent";
import {sendCallBackStats} from "../../../_services/statsServices";

const ModalDialogComponent = (prop) => {
    const {show} = prop
    const {step, response} = useSelector(({sosBreakingReducer}) => sosBreakingReducer)
    const [showSuccess, setShowSuccess] = useState(false);
    const dispatch = useDispatch()

    const handleCloseDialog = () => {
        dispatch(sosBreakingActions.sosBreakingCloseDialog())
        dispatch(sosBreakingActions.sosBreakingReset())
    }

    const handleEmail = () => {
        sendCallBackStats()
        dispatch(sosBreakingActions.sosBreakingSendEmail(emailFactoryComponent(response)))
        dispatch(sosBreakingActions.sosBreakingCloseDialog())
        setShowSuccess(true)
        setTimeout(() => {
            setShowSuccess(false);
            dispatch(sosBreakingActions.sosBreakingReset())
        },TIMEOUT_DURATION);
    }

    const handleCloseConfirmation = () => {
        setShowSuccess(false);
        dispatch(sosBreakingActions.sosBreakingReset())
    }

    return(
        <>
            <Modal className={"sos-modal-dialog"} show={show} onHide={handleCloseDialog}>
                <Modal.Header className={"header-sos"} closeButton />
                <Modal.Body className={"body-sos pt-0"}>
                    {
                        step === sosBreakingStep.NO_BREAK &&
                        <NoBreakComponent handleCloseDialog={handleCloseDialog}/>
                    }
                    {
                        step === sosBreakingStep.BIG_BREAK &&
                        <BigBreakComponent handleCloseDialog={handleCloseDialog} handleEmail={handleEmail}/>
                    }
                </Modal.Body>
            </Modal>
            <ModalEmailConfirmationComponent show={showSuccess} handleClose={handleCloseConfirmation}/>
        </>
    )
}

export default ModalDialogComponent;