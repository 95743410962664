import {trans} from "../translations/translations";

export const endPointELearning = {
    auth: '',
    app: ''
}

export const EndPointCustomerHub = {
    eLearning: "/elearning",
    login: "/customer/login",
    info: "/customer/info",
    me: '/customer/me',
    updateCustomer: '/customer/update',
    checkMail: "/customer/check-email",
    resetPassword: "/customer/reset-password",
    updatePassword: "/customer/update-password",
    updateLegal: "/customer/update-legal",
    log: "/log",
    grayLog: "/graylog",
    getCmd: "/command/customer",
    getRdv: "/rdv/customer",
    getSlots: "/rdv/available",
    reservationRdv: "/rdv/reservation",
    removeRdv: "/rdv/reservation/remove",
    confirmationRdv: "/rdv/confirmation",
    getMeasures: "/weight",
    sendEmail: "/email",
    stats: "/stats",
    getPersonalisation: "/get-personalisation",
    sendPersonalisation: "/send-personalisation",
    getAdvices: "/advices"
}

export const PATH_IMAGE = "/images/meals"

export const genderConstant = {
    FEMALE: "female",
    MALE: "male"
}

export const LegalDocumentName = {
    cgu: "cgv.pdf",
    cgv: "cgv.pdf",
    rgpd: "cgv.pdf"
}

export const endpoints = {
    faq: "/questions-frequentes"
}

export const labelGraphs = {
    ALL: trans('pages.success.graph.all'),
    YEAR: trans('pages.success.graph.views.year'),
    MONTH: trans('pages.success.graph.views.month'),
    WEEK: trans('pages.success.graph.views.week')
}

export const passwordTypeRef = {
    user: 1,
    auto: 2
}

export const ORIGIN = "customerSpace";
export const TIMEOUT_DURATION = 3600;

export const refBrand = {
    equ: 11,
    cja: 1,
    mip: 6
}

export const refRules = {
    el: 1,
}

export const accessStatus = {
    el: false
}