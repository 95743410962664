import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {trans} from "../../translations/translations";
import {sosBreakingActions} from "../../_actions/sosBreakingActions";
import {Col, Row} from "react-bootstrap";
import * as ImIcons from "react-icons/im";
import {ReactComponent as Logo} from "../../asset/image/CommeJaime_logo.svg";
import '../../asset/css/Common/header.scss';


const Header = () => {

    const dispatch = useDispatch();
    const {isLoggedIn} = useSelector(state => {
        const {authReducer} = state;
        return authReducer;
    });

    const sosBreaking = (e) => {
        e.preventDefault();
        dispatch(sosBreakingActions.sosBreakingOpenForm())
    }

    return (
        <Row className="header-block">
            <Col xs={7} sm={"auto"} className={"header-logo text-center"}>
                <Logo/>
            </Col>
            <Col xs={{span: 12, order: 2}} sm={{span: "auto", order: 1}} className={"p-0"}>
                <span className="header-title d-none d-sm-inline-block">{trans('header.title')}</span>
            </Col>
            {
                isLoggedIn && (
                    <Col className="icon-block d-flex justify-content-end justify-content-lg-start ml-auto"
                         xs={{span: 5, order: 1}} sm={{span: 4, order: 3}} xl={2}>
                        <Row>
                            <Col xs={12}>
                                <a href="/" className={"text-decoration-none"} title={trans("header.sos")}
                                   onClick={sosBreaking}>
                                    <Row className={"justify-content-center"}>
                                        <Col xs={"auto"} className="btn-icon p-0 mb-2">
                                            <ImIcons.ImLifebuoy/>
                                        </Col>
                                        <Col xs={12} className={"text-center text-icon"}>
                                            <span>{trans('header.sos')}</span>
                                        </Col>
                                    </Row>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                )
            }
        </Row>
    )
}

export default Header;