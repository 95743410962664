import AxiosInstance from "./axiosServices";
import {EndPointCustomerHub} from "../_constants";
import {SendLog} from "./logServices";

const sendEmailSosBreaking = (email) => {
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }

    return AxiosInstance.post(EndPointCustomerHub.sendEmail, email, config)
        .then(response => {

        })
        .catch(error => {
            const msg = [{
                'COMPONENT': sendEmailSosBreaking.name,
                'ACTION': "Send email SOS Breaking",
                'MSG': error
            }]
            SendLog("ERROR", msg)
        })
}

export const sosBreakingServices = {
    sendEmailSosBreaking
}