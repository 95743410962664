import {sosBreakingConstants, sosBreakingStep} from "../_constants/sosBreakingConstants";

const initialState = {
    openForm: false,
    openDialog: false,
    success: null,
    step: parseInt(sosBreakingStep.Q1),
    response: [],
    emailSend: false,
    stats: false
}

export const sosBreakingReducer = (state = initialState, action ) => {
    switch (action.type){
        case sosBreakingConstants.SOS_STATS_SEND:
            return {
                ...state,
                stats: true
            }
        case sosBreakingConstants.SOS_EMAIL_SEND:
            return {
                ...state,
                emailSend: true
            }
        case sosBreakingConstants.SOS_RESET:
            return {
                ...state,
                openForm: false,
                openDialog: false,
                success: null,
                step: parseInt(sosBreakingStep.Q1),
                response: [],
                emailSend: false,
                stats: false
            }
        case sosBreakingConstants.SOS_OPEN_FORM:
            return {
                ...state,
                openForm: true
            }
        case sosBreakingConstants.SOS_CLOSE_FORM:
            return {
                ...state,
                openForm: false
            }
        case sosBreakingConstants.SOS_OPEN_DIALOG:
            return {
                ...state,
                openDialog: true
            }
        case sosBreakingConstants.SOS_CLOSE_DIALOG:
            return {
                ...state,
                openDialog: false
            }
        case sosBreakingConstants.SOS_UP_STEP:
            return {
                ...state,
                step: action.step
            }
        case sosBreakingConstants.SOS_UP_RESPONSE:
            return {
                ...state,
                response: action.response
            }
        default:
            return state;
    }
}