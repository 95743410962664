import  React from  "react";
import {Route, Redirect} from  "react-router-dom";
import {useSelector} from "react-redux";
import {PAGES_LOGIN} from "./routesConfig";

const  PrivateRoute = (props) => {

    const { isLoggedIn } = useSelector(({authReducer}) => authReducer );

    return  isLoggedIn
        ? (<Route  path={props.path}  exact={props.exact} component={props.component} />)
        : (<Redirect  to={PAGES_LOGIN.path}  />);
};

export  default  PrivateRoute;