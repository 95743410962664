import response1Handler from "./templateResponse/response1/response1Handler";
import response3Handler from "./templateResponse/response3/response3Handler";
import response4Handler from "./templateResponse/response4/response4Handler";

const responseHandlerComponent = (response) => {

    const responseDisplayed = []
    response.map((el) => {
            if( el.R === 1 ) {
                responseDisplayed.push(response1Handler(el))
            }else if( el.R === 2 ){

            }else if( el.R === 3 ){
                responseDisplayed.push(response3Handler(el))
            }else if( el.R === 4 ){
                responseDisplayed.push(response4Handler(el))
            }else{
                responseDisplayed.push({content: "No question , No answer"})
            }
            return true
    })
    return responseDisplayed
}

export default responseHandlerComponent;