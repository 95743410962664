import React, {useCallback, useEffect, useRef, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {contexts} from "./question4Helpers";
import Question4InputComponent from "./question4InputComponent";
import {trans} from "../../../translations/translations";


const Question4Component = (prop) => {

    const {handleResponse} = prop
    const {step, response} = useSelector(({sosBreakingReducer}) => sosBreakingReducer);
    const [responseSelected, setResponseSelected] = useState([])
    const [contextList, setContextList] = useState(contexts)
    const contextRef = useRef([]);

    const handleClickResponse = idx => () => {
        const itemSelected = parseInt(contextRef.current[idx].dataset.id);
        let newResponse;
        if(responseSelected.some( (el) => el === itemSelected ) ){
            newResponse = responseSelected.filter( el => { return el !== itemSelected})
        }else{
            newResponse = responseSelected.map((el) => {return el})
            newResponse.push(itemSelected)
        }
        setResponseSelected(newResponse)
        handleResponse( newResponse.length > 0 ? newResponse : null);
    }
    const updateStatus = useCallback( (selection) => {
        return contextList.map((el) => {
            if (selection.some((id) => id === el.id)) {
                el.status = 'selected'
            } else {
                el.status = 'no-selected'
            }
            return el
        })
    },[contextList])


    useEffect(() => {
        setContextList(updateStatus(responseSelected))
        // eslint-disable-next-line
    },[responseSelected])


    useEffect(() => {
        const recordedResponse = response.filter( response => response.R === step)[0]
        if( recordedResponse && recordedResponse.value){
            setResponseSelected(recordedResponse.value)
            setContextList(updateStatus(recordedResponse.value))
        }
        // eslint-disable-next-line
    }, [response, step]);


    return(
        <Row className={"q-row justify-content-center px-sm-3 pt-4 pb-2"}>
            <div className={"title"}>
                {trans('question4.title', 'sos')}
            </div>
            <Col xs={12} className={"text-question text-center my-3"}>
                <span className={"mr-1"}>{trans('question4.text1', 'sos')}</span>
                <span className={"mr-1 text-pink"}>{trans('question4.text2', 'sos')}</span>
                <span>{trans('question4.text3', 'sos')}</span>
            </Col>
            <Col xs={10} sm={12} className={""}>
                <Row className={"context-row"}>
                    {
                        contextList.map( (el, idx) => {
                            return <Col key={idx} ref={el => contextRef.current[idx] = el} xs={12} sm={6} className={'context-block mb-3'} data-id={el.id} onClick={handleClickResponse(idx)}>
                                <Question4InputComponent text={el.text} status={el.status} />
                            </Col>
                        })
                    }
                </Row>
            </Col>
        </Row>
    )
}

export default Question4Component;