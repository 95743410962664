import {cmdConstants} from "../_constants";

const initialState = {
    onInfosCmdProgress: false,
    infosCmdRequested: false,
    infosCmd: []
}

export const cmdReducer = ( state = initialState, action) => {
    switch (action.type){
        case cmdConstants.CMD_GET_REQUEST:
            return {
                ...state,
                onInfosCmdProgress: true,
                infosCmdRequested: false
            }
        case cmdConstants.CMD_GET_SUCCESS:
            return {
                ...state,
                onInfosCmdProgress: false,
                infosCmdRequested: true,
                infosCmd: [...state.infosCmd, action.cmd]
            }
        case cmdConstants.CMD_GET_FAILURE:
            return {
                ...state,
                onInfosCmdProgress: false,
                infosCmdRequested: true
        }
        default:
            return state;
    }
}