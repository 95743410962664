
import {createStore, applyMiddleware, compose} from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';

const middlewares = [];

if (process.env.REACT_APP_API_NODE_ENV === `dev`) {
    const {logger} = require(`redux-logger`);
    middlewares.push(logger);
}

middlewares.push(thunkMiddleware)

const composeEnhancers = (process.env.REACT_APP_API_NODE_ENV === `development` && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(
        ...middlewares
    )
));

export default store;