import React from "react";
import {Button} from "react-bootstrap";
import '../../asset/css/Buttons/baseBtnPink.scss';

const BasePinkButton = (props) => {

    const { label, onClick, status, data, disabled, prefix } = props;
    const cssClass = "base-btn-pink shadow-none text-uppercase px-4 py-md-3 " + status;
    const dataSet = data ? data : "active";

    return (
        <Button type="submit" bsPrefix={prefix} className={cssClass} onClick={onClick} data-btn={dataSet} disabled={disabled}>{label}</Button>
    )
}

export default BasePinkButton;