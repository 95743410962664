import AfterMealAdvice1 from "./afterMeal/afterMealAdvice1";
import AfterMealAdvice2 from "./afterMeal/afterMealAdvice2";
import AfterMealAdvice3 from "./afterMeal/afterMealAdvice3";
import AfterMealAdvice4 from "./afterMeal/afterMealAdvice4";
import AfterMealAdvice5 from "./afterMeal/afterMealAdvice5";
import BadDayAdvice1 from "./badDay/badDayAdvice1";
import BadDayAdvice2 from "./badDay/badDayAdvice2";
import BadDayAdvice3 from "./badDay/badDayAdvice3";
import BadDayAdvice4 from "./badDay/badDayAdvice4";
import BadDayAdvice5 from "./badDay/badDayAdvice5";
import BadSleepAdvice1 from "./badSleep/badSleepAdvice1";
import BadSleepAdvice2 from "./badSleep/badSleepAdvice2";
import BadSleepAdvice3 from "./badSleep/badSleepAdvice3";
import BadSleepAdvice4 from "./badSleep/badSleepAdvice4";
import BadSleepAdvice5 from "./badSleep/badSleepAdvice5";
import BoringAdvice1 from "./boring/boringAdvice1";
import BoringAdvice2 from "./boring/boringAdvice2";
import BoringAdvice3 from "./boring/boringAdvice3";
import BoringAdvice4 from "./boring/boringAdvice4";
import BoringAdvice5 from "./boring/boringAdvice5";
import FrontScreenAdvice1 from "./frontScreen/frontScreenAdvice1";
import FrontScreenAdvice2 from "./frontScreen/frontScreenAdvice2";
import FrontScreenAdvice3 from "./frontScreen/frontScreenAdvice3";
import FrontScreenAdvice4 from "./frontScreen/frontScreenAdvice4";
import FrontScreenAdvice5 from "./frontScreen/frontScreenAdvice5";
import HolidaysAdvice1 from "./holidays/holidaysAdvice1";
import HolidaysAdvice2 from "./holidays/holidaysAdvice2";
import HolidaysAdvice3 from "./holidays/holidaysAdvice3";
import HolidaysAdvice4 from "./holidays/holidaysAdvice4";
import HolidaysAdvice5 from "./holidays/holidaysAdvice5";
import MakingMealAdvice1 from "./makingMeal/makingMealAdvice1";
import MakingMealAdvice2 from "./makingMeal/makingMealAdvice2";
import MakingMealAdvice3 from "./makingMeal/makingMealAdvice3";
import MakingMealAdvice4 from "./makingMeal/makingMealAdvice4";
import MakingMealAdvice5 from "./makingMeal/makingMealAdvice5";
import NoLossWeightAdvice1 from "./noLossWeight/noLossWeightAdvice1";
import NoLossWeightAdvice2 from "./noLossWeight/noLossWeightAdvice2";
import NoLossWeightAdvice3 from "./noLossWeight/noLossWeightAdvice3";
import NoLossWeightAdvice4 from "./noLossWeight/noLossWeightAdvice4";
import NoLossWeightAdvice5 from "./noLossWeight/noLossWeightAdvice5";
import WorkingAdvice1 from "./working/workingAdvice1";
import WorkingAdvice2 from "./working/workingAdvice2";
import WorkingAdvice3 from "./working/workingAdvice3";
import WorkingAdvice4 from "./working/workingAdvice4";
import WorkingAdvice5 from "./working/workingAdvice5";

const afterMealListResponse = [
    <AfterMealAdvice1 />,
    <AfterMealAdvice2 />,
    <AfterMealAdvice3 />,
    <AfterMealAdvice4 />,
    <AfterMealAdvice5 />,
]
const badDayListResponse = [
    <BadDayAdvice1 />,
    <BadDayAdvice2 />,
    <BadDayAdvice3 />,
    <BadDayAdvice4 />,
    <BadDayAdvice5 />,
]
const badSleepListResponse = [
    <BadSleepAdvice1 />,
    <BadSleepAdvice2 />,
    <BadSleepAdvice3 />,
    <BadSleepAdvice4 />,
    <BadSleepAdvice5 />,
]
const boringListResponse = [
    <BoringAdvice1 />,
    <BoringAdvice2 />,
    <BoringAdvice3 />,
    <BoringAdvice4 />,
    <BoringAdvice5 />,
]
const frontScreenListResponse = [
    <FrontScreenAdvice1 />,
    <FrontScreenAdvice2 />,
    <FrontScreenAdvice3 />,
    <FrontScreenAdvice4 />,
    <FrontScreenAdvice5 />,
]
const holidaysListResponse = [
    <HolidaysAdvice1 />,
    <HolidaysAdvice2 />,
    <HolidaysAdvice3 />,
    <HolidaysAdvice4 />,
    <HolidaysAdvice5 />,
]
const makingMealListResponse = [
    <MakingMealAdvice1 />,
    <MakingMealAdvice2 />,
    <MakingMealAdvice3 />,
    <MakingMealAdvice4 />,
    <MakingMealAdvice5 />,
]
const noLossWeightListResponse = [
    <NoLossWeightAdvice1 />,
    <NoLossWeightAdvice2 />,
    <NoLossWeightAdvice3 />,
    <NoLossWeightAdvice4 />,
    <NoLossWeightAdvice5 />,
]
const workingListResponse = [
    <WorkingAdvice1 />,
    <WorkingAdvice2 />,
    <WorkingAdvice3 />,
    <WorkingAdvice4 />,
    <WorkingAdvice5 />,
]

const response4Handler = (response) => {

    let response4 = [];

    if( response.R === 4 && response.value.some((el) => el === 1) ){
        // response4.push(<MakingMealAdvice5 />)
        response4.push(makingMealListResponse[Math.floor(Math.random() * makingMealListResponse.length)])
    }

    if( response.R === 4 && response.value.some((el) => el === 2) ){
         // response4.push(<AfterMealAdvice2 />)
         response4.push(afterMealListResponse[Math.floor(Math.random() * afterMealListResponse.length)])
     }

    if( response.R === 4 && response.value.some((el) => el === 3) ){
        // response4.push(<WorkingAdvice5 />)
        response4.push(workingListResponse[Math.floor(Math.random() * workingListResponse.length)])
    }

    if( response.R === 4 && response.value.some((el) => el === 4) ){
        // response4.push(<BoringAdvice4 />)
        response4.push(boringListResponse[Math.floor(Math.random() * boringListResponse.length)])
    }

    if( response.R === 4 && response.value.some((el) => el === 5) ){
        // response4.push(<HolidaysAdvice5 />)
        response4.push(holidaysListResponse[Math.floor(Math.random() * holidaysListResponse.length)])
    }

    if( response.R === 4 && response.value.some((el) => el === 6) ){
        // response4.push(<BadDayAdvice5 />)
        response4.push(badDayListResponse[Math.floor(Math.random() * badDayListResponse.length)])
    }

    if( response.R === 4 && response.value.some((el) => el === 7) ){
        // response4.push(<NoLossWeightAdvice4 />)
        response4.push(noLossWeightListResponse[Math.floor(Math.random() * noLossWeightListResponse.length)])
    }

    if( response.R === 4 && response.value.some((el) => el === 8) ){
        // response4.push(<FrontScreenAdvice5 />)
        response4.push(frontScreenListResponse[Math.floor(Math.random() * frontScreenListResponse.length)])
    }

    if( response.R === 4 && response.value.some((el) => el === 9) ){
        // response4.push(<BadSleepAdvice5 />)
        response4.push(badSleepListResponse[Math.floor(Math.random() * badSleepListResponse.length)])
    }

    if( response.R === 4 && response.value.length === 0 ){
         return {content: "Pas de réponse"}
     }

    return { R: response.R, content: response4 }
}

export default response4Handler;