import HungerAdvice1 from "./hunger/hungerAdvice1";
import HungerAdvice2 from "./hunger/hungerAdvice2";
import HungerAdvice3 from "./hunger/hungerAdvice3";
import HungerAdvice4 from "./hunger/hungerAdvice4";
import HungerAdvice5 from "./hunger/HungerAdvice5";
import HungerAdvice6 from "./hunger/hungerAdvice6";
import DesireAdvice1 from "./desire/desireAdvice1";
import DesireAdvice2 from "./desire/desireAdvice2";
import DesireAdvice3 from "./desire/desireAdvice3";
import DesireAdvice4 from "./desire/desireAdvice4";
import DesireAdvice5 from "./desire/desireAdvice5";

const hungerListResponses = [
    <HungerAdvice1 />,
    <HungerAdvice2 />,
    <HungerAdvice3 />,
    <HungerAdvice4 />,
    <HungerAdvice5 />,
    <HungerAdvice6 />,
]

const desireListResponse = [
    <DesireAdvice1 />,
    <DesireAdvice2 />,
    <DesireAdvice3 />,
    <DesireAdvice4 />,
    <DesireAdvice5 />,
]

const response1Handler = (response) => {

     if( response.R === 1 && response.value === 1 ){
         // return {R: response.R, content: <HungerAdvice5 />}
         return {R: response.R, content: hungerListResponses[Math.floor(Math.random() * hungerListResponses.length)]}
     }else if( response.R === 1 && response.value === 2 ){
         // return {R: response.R, content: <DesireAdvice5 />}
         return {R: response.R, content: desireListResponse[Math.floor(Math.random() * desireListResponse.length)]}
     }else{
         return {content: "Pas de réponse"}
     }
}

export default response1Handler;