import {trans} from "../../../translations/translations";

export const contexts = [
    {id: 1, text: trans('question4.helper.meal', 'sos'), order: 1, status: 'no-selected'},
    {id: 2, text: trans('question4.helper.table', 'sos'), order: 3, status: 'no-selected'},
    {id: 3, text: trans('question4.helper.work', 'sos'), order: 4, status: 'no-selected'},
    {id: 4, text: trans('question4.helper.boring', 'sos'), order: 5, status: 'no-selected'},
    {id: 5, text: trans('question4.helper.holidays', 'sos'), order: 10, status: 'no-selected'},
    {id: 6, text: trans('question4.helper.bad', 'sos'), order: 11, status: 'no-selected'},
    {id: 7, text: trans('question4.helper.loss', 'sos'), order: 12, status: 'no-selected'},
    {id: 8, text: trans('question4.helper.screen', 'sos'), order: 13, status: 'no-selected'},
    {id: 9, text: trans('question4.helper.sleep', 'sos'), order: 15, status: 'no-selected'},
]

export const contextsOld = [
    {id: 1, text: "Je prépare un repas", order: 1, status: 'no-selected'},
    {id: 2, text: "Je passe à table", order: 2, status: 'no-selected'},
    {id: 3, text: "Je sors de table", order: 3, status: 'no-selected'},
    {id: 4, text: "Je travaille ou je sors du travail", order: 4, status: 'no-selected'},
    {id: 5, text: "Je m'ennuie", order: 5, status: 'no-selected'},
    {id: 6, text: "Je viens d'avoir une dispute", order: 6, status: 'no-selected'},
    {id: 7, text: "Je suis en famille", order: 7, status: 'no-selected'},
    {id: 8, text: "Je avec des amis / collègues", order: 8, status: 'no-selected'},
    {id: 9, text: "Je suis seul.e", order: 9, status: 'no-selected'},
    {id: 10, text: "Je suis en vacances / week-end", order: 10, status: 'no-selected'},
    {id: 11, text: "J'ai passé une mauvaise journée", order: 11, status: 'no-selected'},
    {id: 12, text: "Je ne perds pas de poids", order: 12, status: 'no-selected'},
    {id: 13, text: "Je suis devant les écrans (TV / Smartphone)", order: 13, status: 'no-selected'},
    {id: 14, text: "Je viens de voir une pub qui me donne envie de manger", order: 14, status: 'no-selected'},
    {id: 15, text: "Je dors mal", order: 15, status: 'no-selected'},
    {id: 16, text: "Par habitude", order: 16, status: 'no-selected'},
    {id: 17, text: "Rien de spécial", order: 17, status: 'no-selected'},
    {id: 18, text: "Autre", order: 18, status: 'no-selected'},
]